<template>
  <div class="card-container" :style="{ alignItems: align ==='left' ? 'flex-start' : 'center', textAlign: align }">
    <div class="title">
      {{ props.title }}
      <slot name="title"></slot>
    </div>
    <div class="sub-title" :class="{'margin_top': props.subTitle ||$slots['sub-title'] }">
      {{ props.subTitle }}
      <slot name="sub-title"></slot>
    </div>
    <div class="page-content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";

const props = defineProps({
  title: String,
  subTitle: String,
  align: String,
});

const align = ref(props.align || "center");
</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: column;
}
.title {
  color: #000;
  font-family: Inter;
  font-size: 52PX;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.04PX;
}
.sub-title {
  max-width: 1052PX;
  color: #706d79;
  font-family: Inter;
  font-size: 20PX;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 26PX */
}
.margin_top {
  margin-top: 12PX;
}
</style>
