<template>
  <div class="mySwiper">
    <Swiper
      :style="{
        '--swiper-pagination-color': '#FE7817',
        '--swiper-pagination-bullet-inactive-color': '#DDDDDD',
        '--swiper-pagination-bullet-inactive-opacity': 1,
        '--swiper-pagination-bullet-size': '12PX',
        '--swiper-pagination-bottom': '13PX',
      }"
      :spaceBetween="30"
      :centeredSlides="true"
      :pagination="{
        clickable: true,
      }"
      :navigation="true"
      :modules="modules"
    >
      <slot></slot>
    </Swiper>
  </div>
</template>

<script setup>
import { Swiper } from "swiper/vue";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
const modules = [Autoplay, Pagination];
</script>

<style scoped>
.mySwiper {
  
}
</style>
