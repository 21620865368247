const en = {
  termipass: "LarePass",
  download: '下载',
  title: {
    content_1: '通往 Olares 的安全桥梁',
    content_2: '让您在<span class="download-title-wrapper">所有平台</span>上畅快访问 Olares'
  },
  dsc_1:
    "如果您还没有 Olares ID，需要首先下载 LarePass 移动端注册。",
  dsc_2:
    "Olares ID 创建和 Olares 设备激活只能在 LarePass 移动端完成。",
  dsc_3:
    " 支持 macOS、Windows、iOS、iPadOS、Android 以及 Chrome 浏览器扩展程序。",
  icon_name_1: "macOS",
  icon_name_2: "Windows",
  icon_name_3: "Google Play",
  icon_name_4: "iOS & iPadOS",
  icon_name_5: "Android",
  icon_name_6: "Chrome",
  title_2: "功能对比",
  category: "类别",
  features: "特性",
  mobile: "移动端",
  desktop: "桌面",
  browser: '浏览器',
  device: '所有品牌',
  chrome_extension: "Chrome 插件",
  account_management: "账户管理",
  account_features_1: "创建 Olares ID",
  account_features_2: "导入 Olares ID",
  account_features_3: "多账户切换",
  account_features_4: "SSO 登录 ",
  device_network_management: "设备及网络管理",
  device_features_1: "激活 Olares",
  device_features_2: "查看资源使用",
  device_features_3: "远程设备控制",
  device_features_4: "管理专用网络",
  knowledge_file_management: "知识与文件管理",
  knowledge_features_1: "跨设备和 SaaS 账户同步文件",
  knowledge_features_2: "管理 Olares 存储的文件",
  knowledge_features_3: "收集在线网页/PDF/视频",
  knowledge_features_4: "备份手机上的照片和文件",
  secret_management: "密码管理",
  secret_features_1:
    "生成、分享、自动填充密码和令牌",
  secret_features_2: "一次性认证管理",
  secret_features_3: "Cookies 同步",
  secret_features_4: "第三方互联网账户集成",
  secret_features_5: "管理可验证凭证 (VC) 卡片",
  productivity: "生产力*",
  productivity_features_1: "在线会议",
  productivity_features_2: "日历",
  productivity_features_3: "即时通讯",
  productivity_features_4: "群组消息",
  ai: "AI*",
  ai_features_1: "与本地 AI 助手对话",
  ai_features_2: "沉浸式翻译",
  ai_features_3: "概括网页和文档",
  ai_features_4: "AI 辅助写作",
  ai_features_5: "AI 辅助搜索",
  wallet: "钱包*",
  wallet_features_1:
    "支持跨 150 多个区块链上 10,000 多种货币的交易",
  wallet_features_2: "基于 Otmoic 协议的跨链桥接（Bridge）和交换（Swap）",
  features_dsc: '*生产力、AI、钱包相关功能即将推出。'
};

export default en;
