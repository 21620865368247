<template>
  <div class="expand-page">
    <div
      class="expand-container"
      v-for="(item, index) in props.data"
      :key="item"
      @mouseenter="() => mouseenterHandler(index)"
      :class="{
        active: modelValue == index,
        [activeClass]: modelValue === index,
      }"
    >
      <div class="list" :title="item.title" :sub-title="item.subTitle">
        <div class="list-title">
          <div class="title-text">{{ item.title }}</div>
          <img src="../../assets/arrow-right.svg" alt="" />
        </div>
        <div class="content-normal">
          <div
            class="list-sub-title"
            :class="{ 'title-margin-top': modelValue === index }"
          >
            {{ item.subTitle }}
          </div>
          <div class="list-read-more">
            <a  class="list-read-more-text" v-if="item.link" :href="item.link" target="_blank">{{ $t('learn_more') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import { debounce } from "@/utils/main";
const props = defineProps({
  data: Array,
  activeClass: String,
  modelValue: Number,
});
const activeClass = ref(props.activeClass);

const emits = defineEmits(["update:modelValue"]);

const handleMouseEnter = (index) => {
  emits("update:modelValue", index);
};

const mouseenterHandler = debounce(handleMouseEnter, 108);
</script>

<style scoped>
.expand-page {
  display: flex;
  flex-direction: column;
  gap: 24PX;
}
.expand-container {
  position: relative;
  width: 548PX;
  padding: 32PX 24PX;
  border-radius: 16PX;
  overflow: hidden;
  background: #fff;
  box-shadow: 14PX 17PX 40PX 4PX rgba(112, 144, 176, 0.08);
  cursor: pointer;
  height: 88PX;
  overflow: hidden;
  transition: all 0.3s ease;
  border-width: 2PX;
  border-style: solid;
  border-color: #fff;
}
.active {
  border-color: #77adff;
}
.list-wrapper {
  margin: 24PX 0;
}
.list-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-family: Inter;
  font-size: 24PX;
  font-style: normal;
  font-weight: 700;
  line-height: 24PX;
  text-transform: capitalize;
}
.list-title img {
  width: 16PX;
  height: 16PX;
}
.title-text {
  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 24PX;
  font-style: normal;
  font-weight: 700;
  line-height: 27PX; /* 100% */
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list-sub-title {
  margin-top: 32PX;
  color: #706d79;
  font-family: Inter;
  font-size: 16PX;
  font-style: normal;
  font-weight: 400;
  line-height: 24PX;
  transition: margin-top 0.2s ease;
  transition-delay: 0.05s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.title-margin-top {
  margin-top: 16PX;
}
.list-read-more {
  margin-top: 20PX;
}
.list-read-more-text {
  color: #706d79;
  font-family: Inter;
  font-size: 16PX;
  font-style: normal;
  font-weight: 400;
  line-height: 24PX;
}
.active-content {
  height: 108PX;
}
</style>
