<template>
  <div>
    <div
      :style="{
        textAlign: position,
        justifyContent: props.stepIcon ? 'space-between' : 'center',
      }"
      class="tabs-top-container"
    >
      <div class="tab-toolbar">
        <div
          class="tab-item"
          :class="{ 'tab-active': active === index }"
          v-for="(item, index) in props.data"
          :key="item.key"
          @click="clickHandler(index, item)"
          @mouseenter="mouseenterHandler(index)"
          @mouseleave="mouseleaveHandler(index)"
        >
          <img
            v-if="item.item || item.iconActive"
            :src="active === index || activeHover === index ? item.iconActive : item.icon"
            alt=""
          />
          <div class="tab-item-text">
            <span>{{ item.name }}</span>
            <div class="tab-placeholder">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div v-if="stepIcon">
        <img
          class="tabs-arrow"
          src="../../assets/arrow-left-button.svg"
          alt=""
          @click="preHandler"
        />
        <img
          class="tabs-arrow tabs-arrow-right"
          src="../../assets/arrow-right-button.svg"
          alt=""
          @click="nextHandler"
        />
      </div>
    </div>
    <div class="tasb-content">
      <Swiper
        class="mySwiper"
        :touchReleaseOnEdges="false"
        :allowTouchMove="false"
        :class="contentClass"
        @swiper="onSwiper"
        loop
        :spaceBetween="30"
      >
        <slot></slot>
      </Swiper>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from "vue";
import { Swiper } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

const props = defineProps({
  data: Array,
  position: String, // left center right
  stepIcon: Boolean,
  contentClass: String,
});
const position = ref(props.position || "left");
const emits = defineEmits(["change"]);

const active = ref(0);
const swiper = ref();
const activeHover = ref(-1)
const dataLength = ref(props.data.length);
const clickHandler = (index, item) => {
  emits("change", index, item);
  swiper.value.slideToLoop(index);
  active.value = index;
};

const preHandler = () => {
  swiper.value.slidePrev();
  if (active.value >= 0) {
    active.value = active.value - 1;
  } else {
    active.value = dataLength.value - 1;
  }
};

const nextHandler = () => {
  swiper.value.slideNext();

  if (active.value < dataLength.value - 1) {
    active.value = active.value + 1;
  } else {
    active.value = 0;
  }
};

const onSwiper = (targer) => {
  swiper.value = targer;
};
const mouseenterHandler = (index)=> {
  activeHover.value = index;
}

const mouseleaveHandler = ()=> {
  activeHover.value = -1;
}
</script>

<style scoped>
.tabs-top-container {
  margin: 0 20PX;
  display: flex;
  align-items: center;
}
.tab-toolbar {
  padding: 4PX;
  background: #f7fafc;
  display: inline-flex;
  border-radius: 12PX;
  gap: 10PX;
}
.tab-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 108PX;
  padding: 10PX 14PX;

  color: #706d79;
  text-align: center;
  font-family: Inter;
  font-size: 16PX;
  font-style: normal;
  font-weight: 400;
  line-height: 20PX; /* 125% */
  letter-spacing: -0.4PX;
  cursor: pointer;
}
.tab-item:hover {
  color: #fe7817;
}

.tab-placeholder {
  font-weight: 500;
  height: 0;
  overflow: hidden;
}
.tab-active {
  border-radius: 12PX;
  background: #fff;
  color: #fe7817;
  font-weight: 500;
}
.tab-item-text {
  margin-top: 2PX;
}
.tasb-content {
  margin-top: 24PX;
  border-radius: 20PX;
}
.tabs-arrow {
  cursor: pointer;
  border-radius: 50%;
  background: #F7FAFC;
}
.tabs-arrow:hover {
  background: #E6E9EB;
  color: #000;
}
.tabs-arrow-right {
  margin-left: 16PX;
}
.mySwiper {
  font-size: 0;
}
</style>
