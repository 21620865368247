<template>
  <div class="mobile-home-container">
    <Page1 />
    <PageCard1 name="4">
      <Page4 />
    </PageCard1>
    <PageCard1 name="5" class="space-col" showBg>
      <Page5 />
    </PageCard1>
    <PageCard1 name="6">
      <Page6 />
    </PageCard1>
    <PageCard1 name="7" class="space-col" showBg>
      <Page7 />
    </PageCard1>
    <PageCard1 name="8">
      <Page8 />
    </PageCard1>
    <PageCard1 name="9">
      <Page9 />
    </PageCard1>
    <PageCard1 name="10" class="space-col" showBg>
      <Page10 />
    </PageCard1>
    <PageCard1 name="11">
      <Page11 />
    </PageCard1>
    <PageCard1 name="12">
      <Page12 />
    </PageCard1>
    <PageCard1 name="13" class="space-col" showBg>
      <Page13 />
    </PageCard1>
    <PageCard1 name="14" v-if="!lang_zh">
      <Page14 />
    </PageCard1>
    <PageCard1 name="17">
      <page17 />
    </PageCard1>
    <Page18  class="space-col"/>
  </div>
</template>
<script setup>
import { onMounted } from "vue";
import { WOW } from "wowjs";
import PageCard1 from "../common/PageCardMobile.vue";

import Page1 from './page1.vue';
import Page4 from "./page4.vue";
import Page5 from "./page5.vue";
import Page6 from "./page6.vue";
import Page7 from './page7.vue';
import Page8 from './page8.vue';
import Page9 from './page9.vue';
import Page10 from './page10.vue';
import Page11 from './page11.vue';
import Page12 from './page12.vue';
import Page13 from './page13.vue';
import Page14 from './page14.vue';
import page17 from "./page17.vue";
import Page18 from "./page18.vue";
import { lang_zh } from '@/utils/lang';

onMounted(() => {
  const wow = new WOW({
    boxClass: "wow",
    animateClass: "animated",
    offset: 10,
    mobile: true,
    live: true,
    scrollContainer: null,
  });
  wow.init();
});
</script>

<style src="wowjs/css/libs/animate.css"></style>
<style src="../../style/mobile.css"></style>
<style scoped>
.mobile-home-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  position: relative;
  max-width: 100%;
}
.space-col {
  margin-top: 60PX;
}
.mobile-home-container::before {
  content: '';
  position: absolute;
  top: -45px;
  left: 0;
  right: 0;
  height: 45px;
  background: #09090B;
}
</style>
<style>
.mobile-home-container .page1-title-highlight {
  background: linear-gradient(90deg, #FB7D47 71.92%, #FF6E6E 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}
.mobile-home-container .page1-dsc-item {
  color: #000;
  font-family: Inter;
  font-size: 20PX;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
.mobile-home-container .link-flash {
  color: #77adff;
  font-weight: 400;
  font-size: 13px;
}
.mobile-home-container .link-flash-wrapper {
  font-size: 11px;
}
</style>