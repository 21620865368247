<template>
  <div class="nav_wrap" :class="[moreScreenHeight ? 'header_light' : 'header_dark', !moreScreenHeight2 ? 'header_dark2' : '', slideUp ? 'header_transparent': '']">
    <div class="nav">
      <div class="nav_loge" @click="goHome">
        <div class="logo">
              <template  v-if="isLarePass">
                <img
                  class="logo-icon"
                  src="../../assets/mobile2/logo.png"
                  alt="logo"
                />
            </template>
            <template v-else>
                <img class="logo-text"   src="../../assets/v2/logo_text.png" alt="" />
            </template>
          </div>
      </div>

      <div class="nav_right">
        <div class="nav_github_text" @click="goMenu(200)">
          <div class="get_start" :class="slideUp ? 'nav_github_ani_hide' : 'nav_github_ani_show'">
            {{ t('get_started') }}
          </div>
        </div>

        <div class="nav_list_menu" @click="clickDownMenu">
          <img v-if="!moreScreenHeight" src="../../assets/mobile2/menu.svg" alt="">
          <img v-else src="../../assets/mobile2/menu-dark.svg" alt="">
        </div>
      </div>
    </div>
    <DownMenu ref="childsDom" :NavList="NavList" @goMenu="goMenu" />
  </div>
</template>


<script setup>
import { ref, onMounted, onUnmounted, computed, onBeforeMount } from 'vue';
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import DownMenu from './drop_down_menu.vue';
import link from '@/common/link';
import { RouteName } from "../../common/constant";
import { lang_zh } from '@/utils/lang';

const route = useRoute()
onBeforeMount(()=>{
  activeNav.value = route.name;

})

const router =  useRouter();
const {t} = useI18n();
let NavList = computed(()=>{
  const list1 = [
    {
      name: t('home'),
      id: RouteName.HOME
    },
    {
      name: t('docs'),
      id: 1
    },
    {
      name: t('market'),
      id: 2
    },
    {
      name: t('blog'),
      id: 3
    },
    {
      name: t('space'),
      id: 4
    },
  ]
  const list2 = [{
    name: t('Zero'),
    id: RouteName.ZERO
  }]

  const list3 = [{
    name: t('download.termipass'),
    id: RouteName.DOWNLOAD,
  }]
  return lang_zh.value ? list1.concat(list2).concat(list3) : list1.concat(list3)
});
let page18height = 0
const activeNav = ref(0);
const slideUp = ref(false);
const moreScreenHeight = ref(false);
const moreScreenHeight2 = ref(false);
const starting = ref(0);

const childsDom = ref(null);

const isLarePass = computed(()=> route.name === RouteName.DOWNLOAD)

const clickDownMenu = () => {
  childsDom.value.handelClick();
};

const windowScroll = () => {
  const screenHeight = document.documentElement.clientHeight;
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;


  if (scrollTop > screenHeight - 40) {
    moreScreenHeight.value = true;
  } else {
    moreScreenHeight.value = false;
  }

  if(screenHeight - scrollTop < 80) {
    moreScreenHeight2.value = true
  }else {
    moreScreenHeight2.value = false
  }
  let scroll = scrollTop - starting.value;
  starting.value = scrollTop;
  if (scroll > 0 && scrollTop > 0) {
    slideUp.value = true;
  } else {
    slideUp.value = false;
  }

  if ((page18height + window.scrollY) >= document.body.offsetHeight || scrollTop < screenHeight) {
        moreScreenHeight.value = false;
    }
}

const goHome = () => {
  router.push("/")
}

const goMenu = (value) => {
  activeNav.value = value;
  if (value === RouteName.HOME) {
    router.push("/");
  } else if (value === 1) {
    window.open(link.docs_path);
  } else if (value == 2) {
    window.open(link.market_path);
  } else if (value == 3) {
    window.open(link.blog_path);
  } else if (value == 4) {
    window.open(link.footer_terminus_space);
  } else if (value == 100) {
    window.open(link.footer_github);
  } else if( value == 200 ) {
    window.open(link.get_started)
  } else if (value == RouteName.ZERO) {
    router.push({
      name: RouteName.ZERO,
    });
  } else if (value == RouteName.DOWNLOAD) {
    router.push({
      name: RouteName.DOWNLOAD,
    });
  }
}

onMounted(() => {
  const page18Mobile = document.querySelector('.page18-mobile');
  page18height = page18Mobile ? page18Mobile.offsetHeight : 0;
  window.addEventListener('scroll', windowScroll);
})

onUnmounted(() => {
  window.removeEventListener('scroll', windowScroll);
})

</script>

<style scoped>
.nav_wrap {
  width: 100%;
  max-width: 100%;
  height: 44px;
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.header_light {
  background: #FFFFFF;
}
.header_light .nav_list_li {
  color: #232323;
}

.nav_loge {
  display: flex;
  align-items: center;
  height: 21px;
}

.header_light .nav_loge span {
  color: #232323;
}

.header_light .get_start {
  background-color: #FFFFFF;
  color: #232323;
}



.header_dark2 {
  background: #09090B;
}

.header_dark .nav_list_li {
  color: #FFFFFF;
}

.header_dark .nav_loge span {
  color: #FFFFFF;
}

/* .header_dark .nav_loge .logo {
  background-color: #09090B;
} */

.header_dark .get_start {
  color: #FFFFFF;
}
.header_transparent {
  background: transparent;
}
.nav {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav_shadow {
  background-color: rgba(255, 255, 255, 0.96);
  box-shadow: 0px 1px 10px #e5e5e5;
}

.nav_loge {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.nav_loge .logo {
  display: flex;
  align-items: center;
}


.nav_loge .logo .logo-text {
  width: 77PX;
  height: 18PX;
  margin-left: 6px;
}
.nav_loge .logo .logo-icon {
  width: 24PX;
  height: 28PX;
}

.nav_loge_ani_hide {
  animation: logoHideAni .4s ease-in-out forwards;
}
@keyframes logoHideAni {
  0% {
    right: 0;
  }
  100% { 
    right: 150PX;
  }
}
.nav_loge_ani_show {
  animation: logoShowAni .4s ease-in-out forwards;
}

@keyframes logoShowAni {
  0% {
    right: 150PX;
  }
  100% { 
    right: 0;
  }
}

.nav_list_wrap {
  position: relative;
}

.nav_list {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.nav_list_ani_hide {
  animation: navAniHide .4s ease-in-out forwards;
}

@keyframes navAniHide {
  0% {
    top: 0;
  }
  100% { 
    top: -98PX;
  }
}

.nav_list_ani_show {
  animation: navAniShow .4s ease-in-out forwards;
}

@keyframes navAniShow {
  0% {
    top: -98PX;
  }
  100% { 
    top: 0;
  }
}


.nav_list_li {
  margin: 0 27PX;
  font-size: 14PX;
  cursor: pointer;
  height: 30PX;
  line-height: 30PX;
}
.active_nav {
  color: rgba(255, 91, 40, 1) !important;
}

.nav_right {
  position: relative;
  width: 110px;
  height: 32px;
}

.nav_github_text {
  width: 82px;
  height: 27px;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
}

.nav_github_ani_hide {
  animation: githubHideAni .4s ease-in-out forwards;
}
@keyframes githubHideAni {
  0% {
    left: 0;
  }
  100% { 
    left: 110px;
  }
}
.nav_github_ani_show {
  animation: githubShowAni .4s ease-in-out forwards;
}

@keyframes githubShowAni {
  0% {
    left: 110px;
  }
  100% {
    left: 0;
  }
}

.get_start {
  width: 100%;
  box-sizing: border-box;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  background: url("../../assets/v2/btn-border.png") no-repeat;
  background-position: center;
  background-size: contain;
  margin-left: 2px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


.nav_list_btn {
  box-sizing: border-box;
  width: 96PX;
  height: 32PX;
  font-size: 12PX;
  line-height: 32PX;
  text-align: center;
  color: #FFFFFF;
  background: #202020;
  border: 1px solid #000000;
  border-radius: 16PX;
  margin-right: 20PX;
}

.nav_list_menu {
  width: 20PX;
  height: 20PX;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>