<template>
      <div
        class="page-card-content"
        :class="{ 'page-card-content-bg': props.showBg }"
        :style="{ background: contentBg, height: fitContentHeight}"
      >
        <div class="page-card-wrapper" :style="{transform: `scale(${page_ratio})`}" ref="child">
          <slot></slot>
        </div>
      </div>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue';
import { page_height, page_ratio } from "@/stores/platform";

const props = defineProps({
  showBg: Boolean,
  contentBg: String,
  name: String,
  lazy: Boolean,
  fitContent: Boolean
});

const child = ref()

const fitContentHeight = computed(() => child.value && props.fitContent ? `${Math.ceil(child.value.offsetHeight * page_ratio.value)}PX` : page_height.value)


</script>

<style scoped>


.page-card-content {
  min-height: 744PX;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-card-height {
}
.page-card-content-bg {
  background: url(../../assets/mask/page-bg.svg) no-repeat;
  background-size: cover;
}
.page-card-wrapper {
  width: 1280PX;
  display: flex;
  justify-content: center;
}
</style>
