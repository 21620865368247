<template>
    <apps_card_mini
      :background-img="item.bg"
      :icon="item.icon"
      :title="item.title"
      :sub-title="item.subTitle"
      v-for="(item, index) in data"
      :key="index"
      :href="item.link"
      class="apps-mini-wrapper"
    >
      <object :class="item.classname" type="image/svg+xml" :data="item.img" width="100%" ></object>
    </apps_card_mini>
</template>
<script setup>
import desktopBG1 from "../../assets/new/desktop-bg-7_1.svg";
import desktopBG2 from "../../assets/new/desktop-bg-7_2.svg";
import apps_card_mini from "./apps_card_mini.vue";
import icon1 from "../../assets/terminusos-icon-7_1.png";
import bg1 from "../../assets/mobile2/terminusos-bg-7_1.svg";
import icon2 from "../../assets/terminusos-icon-7_2.png";
import bg2 from "../../assets/mobile2/terminusos-bg-7_2.svg";
import link from "@/common/link";
import { useI18n } from "vue-i18n";
import { computed } from 'vue';

const { t } = useI18n();
const data = computed(()=> [
  {
    title: t("devbox"),
    subTitle: t("devbox_subtitle"),
    icon: icon1,
    link: link.devbox_leanr_more,
    bg: bg1,
    img: desktopBG1,
    classname: "img-1",
  },
  {
    title: t("control_hub"),
    subTitle: t("control_hub_subtitle"),
    icon: icon2,
    link: link.controlHub_leanr_more,
    bg: bg2,
    img: desktopBG2,
    classname: "img-2",
  },
]);
</script>

<style scoped>
.img-1 {
  width: calc(100% + 6px);
  transform: translateY(-12px);

  position: relative;

}
.img-2 {
  width: calc(100% + 21px);
}
</style>
