<template>
  <div class="page9">
    <Card :title="$t('page9_title')" :sub-title="$t('page9_subtitle')"></Card>
    <div class="page9-content">
      <div
        class="page9-card"
        :class="[active === 0 ? 'expand' : 'page9-card-wrapper']"
        @click="() => (active = 0)"
      >
        <div class="right-top-title">
          <img class="top-title-icon" v-lazy="icon1" alt="" />
          <span class="top-title-text">{{ $t("sync") }}</span>
        </div>
        <div class="top-sub-title">
          {{ $t("sync_dsc") }}
        </div>
        <img class="card-bg-1" :src="syncBG1" alt="" v-show="active === 0" />
      </div>

      <div
        class="page9-card"
        :class="[active === 1 ? 'expand' : 'page9-card-wrapper']"
        @click="() => (active = 1)"
      >
        <div class="right-top-title">
          <img class="top-title-icon" v-lazy="icon2" alt="" />
          <span class="top-title-text">{{ $t("browser") }}</span>
        </div>
        <div class="top-sub-title">
          {{ $t("browser_dsc") }}
        </div>
        <img class="card-bg" :src="syncBG2" alt="" v-show="active === 1" />
      </div>
      <div
        class="page9-card"
        :class="[active === 2 ? 'expand' : 'page9-card-wrapper']"
        @click="() => (active = 2)"
      >
        <div class="right-top-title">
          <img class="top-title-icon" v-lazy="icon3" alt="" />
          <span class="top-title-text">{{ $t("ask") }}</span>
        </div>
        <div class="top-sub-title">
          {{ $t("ask_dsc") }}
        </div>
        <img class="card-bg" :src="syncBG3" alt="" v-show="active === 2" />
      </div>
      <div
        class="page9-card"
        :class="[active === 3 ? 'expand' : 'page9-card-wrapper']"
        @click="() => (active = 3)"
      >
        <div class="right-top-title">
          <img class="top-title-icon" v-lazy="icon4" alt="" />
          <span class="top-title-text">{{ $t("store") }}</span>
        </div>
        <div class="top-sub-title">
          {{ $t("store_dsc") }}
        </div>
        <object class="card-bg" type="image/svg+xml" :data="locale === 'en' ? syncBG4 : syncBG4_zh" alt="" v-show="active === 3" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import Card from "../common/Card.vue";
import syncBG1 from "../../assets/v2/sync-bg-1.png";
import syncBG2 from "../../assets/v2/sync-bg-2.png";
import syncBG3 from "../../assets/v2/sync-bg-3.png";
import syncBG4 from "../../assets/v2/sync-bg-4.svg";
import syncBG4_zh from "../../assets/zh/sync-bg-4.svg";

import icon1 from "../../assets/sync.svg";
import icon2 from "../../assets/browser.svg";
import icon3 from "../../assets/ask.svg";
import icon4 from "../../assets/store.svg";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();


let active = ref(0);
</script>

<style scoped>
.page9 {
  width: 100%;
}
.page9-content {
  display: flex;
  margin-top: 32PX;

  transition: all 200ms ease-in-out 50ms;
  opacity: 1;
}
.page9-card {
  margin-right: 24PX;
  position: relative;
  padding: 32PX 24PX;
  width: 276PX;
  height: 450PX;
  flex-shrink: 0;
  border: 1PX solid #d6d6d6;
  background: drop-shadow(14PX 17PX 40PX rgba(112, 144, 176, 0.08));
  border-radius: 12PX;
  overflow: hidden;
  cursor: pointer;
  transition: all 100ms linear;
}

.page9-card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.page9-card-reset {
  display: block;
}

.expand {
  width: 380PX;
}

.card-bg {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
}
.card-bg-1 {
  position: absolute;
  bottom: -61PX;
  left: -3PX;
  right: 0PX;
  width: calc(100% + 4PX);
}

.right-top-title {
  display: flex;
  align-items: center;
}
.top-title-icon {
  width: 40PX;
  height: 40PX;
  flex-shrink: 0;
}

.top-title-text {
  margin-left: 14PX;
  color: #000;
  font-family: Inter;
  font-size: 24PX;
  font-style: normal;
  font-weight: 700;
  line-height: 24PX; /* 100% */
  text-transform: capitalize;
}
.top-sub-title {
  margin-top: 12PX;
  color: #706d79;
  font-family: Inter;
  font-size: 14PX;
  font-style: normal;
  font-weight: 400;
  line-height: 20PX; /* 142.857% */
}
</style>
