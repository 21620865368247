<template>
  <div class="page18">
    <div
      class="page18-top split"
      :style="{ paddingBottom: `calc(105PX * ${page_ratio})` }"
    >
      <div>
        <div class="page18-title">{{ $t("page18_title") }}</div>
        <div class="page18-button">
          <a
            :href="link.footer_get_started"
            target="_blank"
            rel="noopener noreferrer"
          >
            <get_started></get_started>
          </a>
          <a
            class="github-button-container"
            :href="link.footer_github"
            target="_blank"
            rel="noopener noreferrer"
            @mouseenter="mouseenterHandler1(index)"
            @mouseleave="mouseleaveHandler1(index)"
          >
            <img :src="activeHover ? githubIcon : githubIcon2" />
            <a class="github-button">Github</a>
          </a>
        </div>
      </div>
    </div>
    <div class="page18-bottom">
      <FooterPC></FooterPC>
    </div>
  </div>
</template>

<script setup>
import get_started from "./get_started.vue";
import link from "@/common/link";
import { ref } from "vue";
import githubIcon from "../../assets/v2/github-icon.svg";
import githubIcon2 from "../../assets/v2/github-icon2.svg";
import { page_ratio } from "@/stores/platform";
import FooterPC from "../common/FooterPC.vue";

const activeHover = ref(-1);

const mouseenterHandler1 = (index) => {
  activeHover.value = index;
};

const mouseleaveHandler1 = () => {
  activeHover.value = -1;
};
</script>

<style scoped>
.page18 {
  width: 100%;
  padding-top: 56PX;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page18-top {
  flex: 1;
  background: #0d0d0d url(@/assets/v2/footer-bg.svg);
  background-position: 50% 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page18-title {
  color: var(--www-risecalendar-com-nero, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 52PX;
  font-style: normal;
  font-weight: 400;
  line-height: 72PX; /* 138.462% */
  letter-spacing: -1.1PX;
}
.page18-button {
  flex: 1;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 48PX;
  gap: 32PX;
}
.page18-img-right {
  margin-left: 32PX;
}
.github-button-container {
  display: flex;
  width: 168PX;
  height: 56PX;
  padding: 16PX 35PX;
  justify-content: center;
  align-items: center;
  color: #000;
  gap: 8PX;
  flex-shrink: 0;
  border-radius: 8PX;
  background: var(--www-risecalendar-com-nero, #fff);
  box-shadow: 0PX 1PX 2PX 0.5PX rgba(0, 0, 0, 0.1),
    0PX 1PX 4PX 0PX rgba(0, 0, 0, 0.05), 0PX 0PX 1PX 0PX rgba(0, 0, 0, 0.16);
  cursor: pointer;
}
.github-button-container:hover {
  color: #fff;
  background: linear-gradient(90deg, #ff7b01 0%, #ff4e42 46.71%, #8dbbff 100%);
}
.github-button-container:hover .github-button {
  color: #fff;
}
.github-button {
  text-align: center;
  font-family: Inter;
  font-size: 20PX;
  font-style: normal;
  font-weight: 500;
  line-height: 20PX; /* 100% */
  letter-spacing: -0.36PX;
}
.split {
  width: 100%;
  border-bottom: 1PX solid var(--www-risecalendar-com-shark, #2f3034);
}
.page18-bottom {
  flex: 1;
}
</style>
