<template>
  <div class="page1">

    <Star />
    <div class="page1_enter">
      <div class="img-left">
        <img  src="../../assets/mobile2/grid-left.png" alt="">
      </div>
      <div class="img-right">
        <img src="../../assets/mobile2/grid-right.png" alt="">
      </div>
      <div class="header_ray header_ray2">
        <div class="ray2"  >
        <div class="header_ray_bulr1"></div>
          </div>
      </div>
      <div class="content_ray">
        <div class="content_1 content">{{ $t("terminus_dsc_1") }}</div>

        <div class="content_box" ref="contentBoxRef">
          <div class="content_2">
            <div class="content">{{ $t('terminus_dsc_1_0') }} </div>
          </div>

          <div class="content_2">
            <div class="content">{{ $t('terminus_dsc_1_1') }} </div>
          </div>

          <div class="content_2">
            <div class="content">{{ $t('terminus_dsc_2_1') }} </div>
          </div>

          <div class="content_2">
            <div class="content">{{ $t('terminus_dsc_3_1') }} </div>
          </div>

          <div class="content_2">
            <div class="content">{{ $t('terminus_dsc_4_1') }} </div>
          </div>
          <div class="content_2">
            <div class="content">{{ $t('terminus_dsc_5_1') }} </div>
          </div>
        </div>
      </div>

      <div class="footer_ray footer_ray2">
        <div class="ray4" >
          <div class="footer_ray_bulr1"></div>
        </div>
      </div>

      <div class="stared">
        <div class="stared_inter">
          <Link-Target :src="link.get_started">{{
            $t("get_started")
          }}</Link-Target>
          <div class="enter-img-wrapper">
            <div class="enter">
              <img src="../../assets/arrow-right2.svg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="spot_wrap">
        <div class="spot">
          <span></span>
        </div>
      </div>

      <img class="star" src="../../assets/v2/star.svg" alt="star" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue';

import Star from "../common/Star";
import link from "../../common/link";


const contentBoxRef = ref();

onMounted(() => {
  const contentBox = contentBoxRef.value.children;

  for (let i = 0; i < contentBox.length; i++) {
    const element = contentBox[i];
    element.style['animation-duration'] = `${contentBox.length * 2}s`;
    element.style['animation-delay'] = `${0.8 + i * 2}s`;
  }

  const style = ref(null);
  style.value = document.createElement('style');
  document.head.appendChild(style.value);

  nextTick(() => {
    style.value.innerHTML = `@keyframes flipAni {
      0% {top: 100%; opacity: 1}
        ${100/(contentBox.length * 2) * 1}% {top: 0; opacity: 1}
        ${100/(contentBox.length * 2) * 2}% {top: 0; opacity: 1}
        ${100/(contentBox.length * 2) * 2.3}% {top: -30; opacity: 0}
        ${100/(contentBox.length * 2) * 3}% {top: -100%; opacity: 0}
        31% {top: 100%; opacity: 0}
        95% {top: 100%; opacity: 1}
        100% {top: 100%; opacity: 1}
    }`;
  })
})

</script>

<style scoped src="../../style/raw.mobile.animation.css" />
<style scoped src="../../style/content.mobile.animation.css" />
<style scoped src="../../style/star.mobile.animation.css" />
<style scoped>
.page1 {
  width: 100%;
  background-color: #09090b;
  min-height: max(calc(100vh - var(--vh-offset, 0px) - 44px), 490px);
  position: relative;
}

.page1_enter {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 34px 0 124px;
  overflow: hidden;
}
.img-left  {
  position: absolute;
  top: 90px;
  bottom: 161px;
  left: -5%;
  width: 32%;
  z-index: 0;
  pointer-events: none;
}
.img-right  {
  position: absolute;
  top: 90px;
  bottom: 161px;
  right: -5%;
  width: 32%;
  z-index: 0;
  pointer-events: none;
}
.img-left img, .img-right img {
  width: 100%;
  height: 100%;
}

.page1_enter_bg-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.page1_enter_bg-wrapper img {
  width: 100%;
  height: 100%;
}

.stared {
  width: 112px;
  height: 32px;
  position: absolute;
  bottom: 168px;
  display: inline-flex;
  background: #010001 url("../../assets/v2/btn-border.png") no-repeat;
  background-position: center;
  background-size: 100% 100%;
  animation: staredAni 1s ease-in-out 0.5s forwards;
  opacity: 0;
}

@keyframes staredAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.stared_inter {
  width: 100%;
  height: 100%;
  text-align: left;
  position: relative;

  color: var(--Basic-White, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 10px;
}

.enter-img-wrapper {
  position: relative;
  width: 4px;
  height: 10px;
  margin-left: 20px;
  height: 100%;
}

.stared_inter .enter {
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 10px;
  animation: enterdAni 2s linear infinite;
  transform: translateY(-50%);
  font-size: 0;
  right: 0;
}

@keyframes enterdAni {
  0% {
    right: 12px;
  }
  50% {
    right: 4px;
  }
  100% {
    right: 12px;
  }
}

.spot_wrap {
  width: 20px;
  height: 28px;
  box-sizing: border-box;
  border: 1px solid #fff;
  border-radius: 10px;
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto;
  animation: staredAni 1s ease-in-out 0.5s forwards;
  opacity: 0;
  will-change: opacity;
}

.spot {
  width: 100%;
  height: 100%;
  position: relative;
}

.spot span {
  display: inline-block;
  width: 0;
  height: 8px;
  border: 1px solid #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 2px;
  bottom: 0;
  margin: auto;
  animation: spotAni 2s linear infinite;
}

@keyframes spotAni {
  0% {
    top: -10px;
  }
  50% {
    top: 8px;
  }
  100% {
    top: -10px;
  }
}

</style>
<style scoped>
  .header_ray2 {
    height: 68px;
  }
  .footer_ray2 {
    height: 68px;

  }
</style>