<template>
  <canvas ref="canvasRef" class="canvas"></canvas>
</template>

<script>
import { onMounted, ref, onUnmounted } from 'vue';

export default {
  name: 'Star',
  components: {
  },

  setup() {
    const canvasRef = ref(null);
    const screen = ref({
      width: window?.innerHeight,
      height: window?.innerHeight,
    })
    const cursor = ref({
      x: 0,
      y: 0
    })

    const resize = () => {
      screen.value.width = window.innerWidth
      screen.value.height = window.innerHeight
      canvasRef.value.width = screen.value.width
      canvasRef.value.height = screen.value.height
    }

    const updateCursor = (e) => {
      cursor.value = {
        x: e.clientX,
        y: e.clientY
      }
    }

    const stars = ref([]);
    const starFlow = ref(0.5);

    onMounted(() => {
      window.addEventListener('resize', () => resize());
      window.addEventListener('mousemove', e => updateCursor(e));

      let ctx = canvasRef.value.getContext('2d');

      function Star(context, positions) {
        this.context = context
        this.angle = Math.random() * Math.PI * 3
        this.star = {
          radius: Math.random(),
          color: '#a1a1a1',
          x: positions.x,
          y: positions.y,
          speed: {
            x: Math.cos(this.angle)*7,
            y: Math.sin(this.angle)*7
          }
        }
      }

      Star.prototype.randIn = function(min, max) {
        return Math.floor(Math.random(max - min) + min)
      }

      Star.prototype.draw = function() {
        this.star.x += this.star.speed.x
        this.star.y += this.star.speed.y
        this.context.beginPath()
        this.context.arc(
          this.star.x,
          this.star.y,
          (this.star.radius ++) / 60,
          Math.PI * 2,
          false
        )
        this.context.fillStyle = this.star.color
        this.context.shadowColor = this.star.color
        this.context.shadowBlur = 15
        this.context.closePath()
        this.context.fill()
      }

      const getRandomPos = () => {
        const res = {
          x: (screen.value.width / 2) + (Math.random()*10),
          y: (screen.value.height / 2) + (Math.random()*10)
        }
        return res
      }

      const loop = () => {
        window.requestAnimationFrame(loop)
        drawBg()
        drawStars()
        updateStars()
        removeOldStars()
      }

      const drawBg = () => {
        ctx.fillStyle = '#09090B';
        ctx.fillRect(0, 0, screen.value.width, screen.value.height)
      }

      const drawStars = () => {
        for (let i = 0; i < starFlow.value; i++) {
          const star = new Star(
            ctx,
            getRandomPos()
          )
          stars.value.push(star)
        }
      }

      const updateStars = () => {
        for (const _star of stars.value) {
          _star.draw()
        }
      }

      const removeOldStars = () => {
        setTimeout(() => {
          for (let i = 0; i < starFlow.value; i++) {
            stars.value.shift()
          }
        }, 2500)
      }
      
      resize();
      loop();
    });
    

    onUnmounted(() => {
      window.removeEventListener('resize', () => {});
    });

    return {
      canvasRef
    }
  }
}
</script>

<style scoped>
 .canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: #FFF;
}
</style>
