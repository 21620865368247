<template>
  <div
    class="lang-container"
    :class="[props.dark ? 'lang-container-dark' : '']"
  >
    <span @click="clickHandler" class="lang-text lang-text-active">{{
      currentData.name
    }}</span>
    <div class="lang-icon">
      <div
        class="shape shape-active"
        :class="[props.dark ? 'shape-active-light' : '']"
      ></div>
    </div>

    <div class="lang-popup">
      <div
        class="lang-popup-container"
        :class="[props.dark ? 'lang-popup-container-dark' : '']"
      >
        <div
          class="popup-content-item"
          v-for="item in langs"
          :key="item.value"
          @click="langChange(item)"
        >
          <span>{{ item.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useI18n } from "vue-i18n";
import { ref, computed, defineProps } from "vue";
const props = defineProps({
  dark: Boolean,
  slideUp: Boolean,
});

const { locale } = useI18n();
const visible = ref(false);

const langs = [
  { label: "English", value: "en", name: "En" },
  { label: "简体中文", value: "zh", name: "Zh" },
];

const clickHandler = () => {
  visible.value = !visible.value;
};

const currentData = computed(() =>
  langs.find((item) => item.value === locale.value) || langs[0]
);

const langChange = (item) => {
  locale.value = item.value;
  visible.value = false;
  localStorage.setItem("lang", item.value);
};
</script>
<style>
.lang-container {
  width: 90PX;
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
  gap: 2.293PX;
  position: absolute;
  right: 0;
  top: -24PX;
  bottom: -24PX;
  padding: 0 30PX;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.lang-text {
  font-size: 16PX;
  font-style: normal;
  font-weight: 500;
  line-height: 16PX; /* 100% */
  letter-spacing: 0.2PX;
}

.lang-container:hover .lang-text-active {
  color: #ff5b28;
}

.lang-popup {
  /* height: 0; */
  overflow: hidden;
  height: 0;

  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, calc(calc(100% - 2PX)));
  width: 170PX;
  box-shadow: 0PX 2PX 4PX 0PX rgba(0, 0, 0, 0.1);
}
.lang-popup-container {
  padding: 20PX 16PX;

  border-radius: 6PX;
  background: #fff;

  display: flex;
  flex-direction: column;
  gap: 20PX;
  color: #706d79;
  animation: 3s linear;
}

.lang-container:hover .lang-popup {
  height: auto;
}
.lang-container-dark {
  color: #fff;
}

.lang-popup-container-dark {
  background: rgba(47, 47, 47, 0.8);
  color: #fff;
}

.popup-content-item {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16PX;
  font-style: normal;
  font-weight: 400;
  line-height: 16PX; /* 100% */

  text-align: center;
  font-family: Roboto;
  font-size: 16PX;
  font-style: normal;
  font-weight: 400;
  line-height: 16PX; /* 100% */
}
.popup-content-item:hover {
  color: #ff5b28;
}

.popup-content-item::before {
  content: "";
  display: block;
  width: 8PX;
  height: 8PX;
  margin-top: 1PX;
  background: #706d79;
  opacity: 0.5;
  border-radius: 50%;
  margin-right: 14PX;
}

.lang-popup-container-dark .popup-content-item::before {
  background: #fff;
}

.lang-icon {
  position: relative;

  width: 7PX;
  height: 6PX;
}

.shape {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #232323;
  animation: transform 2s;
  clip-path: path(
    "M6.38496 5.18782C6.80305 5.18782 7.03649 4.70521 6.77696 4.37743L3.99844 0.868361C3.79826 0.615542 3.41463 0.615542 3.21445 0.86836L0.435933 4.37743C0.176396 4.70521 0.409841 5.18782 0.827929 5.18782L6.38496 5.18782Z"
  );
}
.shape-active-light {
  background-color: #fff;
}
.lang-container:hover .shape-active {
  background-color: #ff5b28;
  transform: rotateZ(180deg);
}
</style>
