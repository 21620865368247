<template>
  <span class="my-link-wrapper" @click="clickHandler">
    <slot></slot>
  </span>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  src: String,
});
const clickHandler = () => {
  window.open(props.src);
};
</script>

<style scoped>
.my-link-wrapper {
    cursor: pointer;
}
</style>
