import { createVNode, render } from 'vue';
import Message from './Message.vue';
import { MESSAGE_TIMEOUT } from "./../../common/constant.js";

const div = document.createElement('div')
document.body.appendChild(div)

let timer = null;

const renderMessage = (vnode, timeout) => {
   render(null, div);
   render(vnode, div);
   clearTimeout(timer);
   timer = setTimeout(() => {
      render(null, div);
   }, timeout);
};

export default {
   error: (text, timeout = MESSAGE_TIMEOUT) => {
      const vnode = createVNode(Message, { type: "error", text, timeout });
      renderMessage(vnode, timeout);
   },
   warn: (text, timeout = MESSAGE_TIMEOUT) => {
      const vnode = createVNode(Message, { type: "warn", text, timeout });
      renderMessage(vnode, timeout);
   },
   success: (text, timeout = MESSAGE_TIMEOUT) => {
      const vnode = createVNode(Message, { type: "success", text, timeout });
      renderMessage(vnode, timeout);
   },
   info: (text, timeout = MESSAGE_TIMEOUT) => {
      const vnode = createVNode(Message, { type: "info", text, timeout });
      renderMessage(vnode, timeout);
   },
};