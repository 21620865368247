import { RouteName } from "../common/constant";
import { createRouter, createWebHistory } from "vue-router";

import LayoutPC from "../layout/layout_base.vue";
import Home from '../pages/home.vue'
import { lang_zh } from "@/utils/lang";

const children1 = [
  {
    path: "/",
    name: RouteName.HOME,
    component: Home,
  },
  {
    path: "/installation",
    name: "Installation",
    component: () => import("../pages/install.vue"),
  },
  {
    path: "/talent",
    name: "Talent",
    component: () => import("../pages/hiring.vue"),
  },
  {
    path: "/talentDetail",
    name: "Details",
    component: () => import("../pages/hiringDetail.vue"),
  },
  {
    path: "/download",
    redirect: '/larepass',
  },
  {
    path: "/larepass",
    name: RouteName.DOWNLOAD,
    component: () => import("../pages/download.vue"),
    meta: {
      nav_dark: true,
    }
  },
]

const children2 = [
  {
    path: "/zero",
    name: RouteName.ZERO,
    component: () => import("../pages/zero.vue"),
  }
]

const routes = [
  {
    path: "/",
    component: LayoutPC,
    name: "layout",
    children: lang_zh.value ? children1.concat(children2) : children1
  },
  {
		path: '/:catchAll(.*)*',
    redirect: '/'
  }
];


const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return {
      top: 0,
    };
  },
});


export default router;
