<template>
  <div class="page10-container" ref="page10Ref" @scroll="scrollHander">
    <div class="left">
      <div class="page10-icon">
        <img src="../../assets/framework.svg" alt="" />
      </div>
      <Card align="left">
        <template #title>
          <div class="page10-title" v-html="$t('page10_title')"></div>
        </template>
        <template #sub-title>
          <div class="page10-subtitle">
            {{ $t('page10_subtitle') }}
          </div>
        </template>
      </Card>
    </div>
    <div class="right-wrapper">
      <div class="right">
        <div
          v-for="(item, index) in icons"
          :key="item"
          class="icon-container"
          :class="{ highlight: active === index }"
        >
          <div class="icon-wrapper">
            <img v-lazy="require(`@/assets/v2/${item.icon}.svg`)" alt="" />
          </div>
        </div>
      </div>
      <!-- <div class="rectangle-wrapper">
        <div class="rectangle"></div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import Card from "../common/CardMobile.vue";
const icons = [
  {icon: "ai-1", width: 84},
  {icon: "ai-2", width: 84},
  {icon: "ai-3", width: 102},
  {icon: "ai-4", width: 82},
  {icon: "ai-5", width: 111},
  {icon: "ai-6", width: 154},
  {icon: "ai-7", width: 111},
  {icon: "ai-8", width: 137},
  {icon: "ai-9", width: 97},
  {icon: "ai-10", width: 169},
  {icon: "ai-11", width: 96}
];

</script>

<style scoped>
.page10-container {
  width: 100%;
  padding-bottom: 60PX;
}
.page10-icon {
  padding-left: 29px;
}
.page10-icon img {
  width: 48px;
  height: 48px;
}
.page10-title {
  text-align: left;
  padding-left: 29px;
}
.page10-subtitle {
  text-align: left;
}
.left {
  flex: 1;
}
.right-wrapper {
  display: flex;
  margin-top: 40px;
}
.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  gap: 10px;
  padding-left: 29px;
}
.rectangle-wrapper {
  position: sticky;
  top: 0;
  z-index: 3;
  width: 0PX;
  height: 100vh;
}
.rectangle {
  position: absolute;
  left: -100vw;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  z-index: 3;
}
.rectangle::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  bottom: 0;
  content: "";
  background: url(../../assets/mask/page10-mask.svg) no-repeat;
  background-size: cover;
  z-index: 999;
}
.icon-container {
  display: inline-flex;
  align-items: center;
}
.icon-wrapper {
  padding:0 20px;
  height: 47px;
  box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.08);
  border-radius: 10px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-wrapper img {
  height: 25px;
}
.highlight {
  /* border: 2px solid #77adff; */
}
</style>
