<template>
  <div class="page10-container" :style="{maxHeight: `calc(${ Math.floor(page_ratio * 100)}vh)`}" ref="page10Ref">
    <div class="left">
      <div class="page10-icon">
        <img src="../../assets/framework.svg" alt="" />
      </div>
      <Card align="left" :sub-title="$t('page10_subtitle')">
        <template #title>
          <div v-html="$t('page10_title')"></div>
        </template>
      </Card>
    </div>
    <div class="right" :style="{maxHeight: ` calc(100vh / ${page_ratio})`}" @scroll="scrollHander">
      <div class="right-wrapper">

      <div
        v-for="(item, index) in icons"
        :key="item"
        class="icon-container"
        :class="{ highlight: active === index }"
      >
        <img :class="item.split('.')[0]"  v-lazy="require(`@/assets/v2/${item}`)" alt="" />
      </div>
    </div>
    </div>
    <div class="rectangle">
      <div class="rectangle-child"></div>
    </div>
  </div>
</template>

<script setup>
import Card from "../common/Card.vue";
import { ref } from "vue";
import { page_ratio } from "@/stores/platform";

const icons = [
  "ai-1.svg",
  "ai-3.svg",
  "ai-2.svg",
  "ai-5.svg",
  "ai-6.svg",
  "ai-4.png",
  "ai-7.svg",
  "ai-8.svg",
  "ai-9.png",
  "ai-10.png",
  "ai-11.png",
  "ai-1.svg",
  "ai-2.svg",
  "ai-3.svg",
  "ai-4.png",
  "ai-5.svg",
  "ai-6.svg",
  "ai-7.svg",
  "ai-8.svg",
  "ai-9.png",
  "ai-10.png",
  "ai-11.png",
];
const default_highlight_index = 1;
const page10Ref = ref();
const active = ref(default_highlight_index);
const scrollStep = 72/page_ratio.value;
const scrollHander = (e) => {
  active.value = Math.ceil(e.target.scrollTop/scrollStep) + default_highlight_index
}
</script>

<style scoped>
.page10-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
}
.page10-icon {
  margin-bottom: 32PX;
}
.page10-icon img {
  width: 64PX;
  height: 64PX;
}
.left {
  flex: 705;
  margin-right: 53PX;
  margin-top: 100PX;
  height: fit-content;
}
.right {
  flex: 575;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
  padding: 1PX 0;
  padding-right: 20PX;
  margin-right: -20PX;
  flex-wrap: nowrap;
}
.right-wrapper {
  padding-top: 100PX;
  margin-left: 46PX;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}
.rectangle {
  position: sticky;
  top: 0PX;
}
.rectangle::before {
  position: absolute;
  left: -514PX;
  width: 575PX;
  top: 0;
  content: "";
  height: 227PX;
  background: linear-gradient(0, rgba(255,255,255,0.1) 0%, #f3fbff 100%);
  background-size: cover;
  z-index: 999;
  top: 0;
}
.rectangle::after {
  position: absolute;
  left: -514PX;
  width: 575PX;
  bottom: 0;
  content: "";
  height: 153PX;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #fbfdfe 100%);
  background-size: cover;
  z-index: 999;
}
.icon-container {
  display: flex;
  align-self: flex-start;
  align-items: center;
  height: 72PX;
  padding: 0 32PX;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16PX;
  background: #fff;
  box-shadow: 14PX 17PX 40PX 4PX rgba(112, 144, 176, 0.08);
  margin-bottom: 16PX;
  border: 2PX solid #fff;
}
.highlight {
  border: 2PX solid #77adff;
}
.ai-1 {
  width: 130PX;
  height: 141PX;
}
.ai-2 {
  width: 114PX;
  height: 48PX;
}
.ai-3 {
  width: 143PX;
  height: 36PX;
}
.ai-4 {
  width: 127PX;
  height: 43PX;
}
.ai-5 {
  width: 170PX;
  height: 56PX;
}
.ai-6 {
  width: 236PX;
  height: 34PX;
}
.ai-7 {
  width: 170PX;
  height: 41PX;
}
.ai-8 {
  width: 210PX;
  height: 40PX;
}
.ai-9 {
  width: 150PX;
  height: 40PX;
}
.ai-10 {
  width: 259PX;
  height: 36PX;
}
.ai-11 {
  width: 147PX;
  height: 40PX;
}

</style>
