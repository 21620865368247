import { createApp } from "vue";
import App from "./App.vue";
import "amfe-flexible";
import axios from "axios";
import router from "./router";
import animated from "animate.css";
import Message from "./components/Message";
import "./assets/fonts/font.css";
import "./style/common.css";
import "./utils/rem";
import i18n from "./i18n";
import VueLazyLoad from 'vue3-lazyload'
import LinkTarget from './components/common/Link-Target.vue'
import vhCheck from 'vh-check'

vhCheck()
const app = createApp(App);
app.use(router);
app.use(animated);
app.component('Link-Target', LinkTarget)

app.config.globalProperties.$httpAxios = axios;
app.config.globalProperties.$message = Message;

app.use(i18n);
app.use(VueLazyLoad, {
  observerOptions: { 
    rootMargin: '900PX' },
});
app.mount("#app");
