<template>
  <div class="desktop-test">
    <apps_card
      :background-img="bg"
      :icon="terminusos2Icon"
      :title="$t('vault_title')"
      :sub-title="subTitle"
      :href="link.vault_learn_more"
    >
      <object class="desktop-img-2" type="image/svg+xml" :data="desktopBG11" width="100%" ></object>
    </apps_card>
  </div>
</template>

<script setup>
import desktopBG11 from "../../assets/new/desktop-bg-3.svg";
import apps_card from "./apps_card.vue";
import terminusos2Icon from "../../assets/terminusos-icon-3.png";
import bg from "../../assets/mobile2/terminusos-bg-3.svg";
import link from "@/common/link";
import { useI18n } from "vue-i18n";
import { computed } from 'vue';

const { t } = useI18n();
const subTitle = computed(()=> [
  t("vault_subtitle_1"),
  t("vault_subtitle_2"),
  t("vault_subtitle_3")
]);
</script>

<style scoped>
.desktop-img-2 {
  width: calc(100% + 25px);
  transform: translateX(-10px);
}
</style>
