<template>
  <div class="page11">
    <Card :title="$t('page11_title')">
      <template #sub-title>
        <span v-html="$t('page11_subtitle')"></span>&nbsp;
        <ReadMore :link="link.AI_assistant_learn_more"></ReadMore>
      </template>
    </Card>
    <div class="page12-content">
      <img
        class="img-1"
        v-lazy="img1"
        alt=""
        v-show="active === 0"
      />
      <object
        type="image/svg+xml"
        :data="locale === 'en' ? img2 : img2_zh"
        class="img-2"
        v-if="active === 1"
      />
      <object
        type="image/svg+xml"
        :data="locale === 'en' ? img3 : img3_zh"
        class="img-3"
        v-if="active === 2"
      />
      <object
        type="image/svg+xml"
        :data="locale === 'en' ? img4 : img4_zh"
        src="../../assets/v2/assistant-4.svg"
        alt=""
        v-if="active === 3"
      />
      <div class="right">
        <Expand
          :data="list"
          v-model="active"
          activeClass="page11-expand-active-class"
        ></Expand>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import Card from "../common/Card.vue";
import Expand from "../common/Expand.vue";
import { useI18n } from "vue-i18n";
import ReadMore from "./ReadMore.vue";
import link from "@/common/link";
import img1 from '../../assets/v2/assistant-1.webp'
import img2 from "../../assets/v2/assistant-2.svg";
import img3 from "../../assets/v2/assistant-3.svg";
import img4 from "../../assets/v2/assistant-4.svg";
import img2_zh from "../../assets/zh/assistant-2.svg";
import img3_zh from "../../assets/zh/assistant-3.svg";
import img4_zh from "../../assets/zh/assistant-4.svg";

const { t, locale } = useI18n();

const list = computed(()=> [
  {
    title: t("build_agent"),
    subTitle: t("build_agent_dsc"),
  },
  {
    title: t("seamless_integration_knowledge"),
    subTitle: t("seamless_integration_knowledge_dsc"),
  },
  {
    title: t("complete_jobs"),
    subTitle: t("complete_jobs_dsc"),
  },
  {
    title: t("agent_service"),
    subTitle: t("agent_service_dsc"),
  },
]);

const active = ref(0);
</script>

<style scoped>
.page11 {
  width: 100%;
}
.page12-content {
  display: flex;
  justify-content: space-between;
  margin-top: 35PX;
}
.img-1 {
  margin-left: -62PX;
  width: 740PX;
}
.page11-read-more {
  color: #77adff;
  font-family: Inter;
  font-size: 20PX;
  font-style: normal;
  font-weight: 400;
  line-height: 28PX;
  letter-spacing: -0.4PX;
  text-decoration-line: underline;
}
.img-2 {
  width: 591PX;
  height: 428PX;
}
.img-3 {
  width: 638PX;
  height: 455PX;
}
.img-4 {
  width: 706PX;
  height: 488PX;
}
</style>
<style>
.page11-expand-active-class {
  height: 176PX !important;
}
</style>
