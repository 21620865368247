<template>
  <div class="page17">
    <Card>
      <template #title>
        <div class="page17-title" v-html="$t('page17_title')"></div>
      </template>
    </Card>
    <div class="thanks-list">
      <div class="list-content">
        <img
          v-for="(item, index) in list"
          :key="index"
          class="list-item"
          :style="{width: `${item.width}px`}"
          v-lazy="require(`@/assets/v2/${item.path}`)"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Card from "../common/CardMobile.vue";

const list = [
  { index: 1, path: "projects-1.webp", link: "", width: "117" },
  { index: 2, path: "projects-2.webp", link: "", width: "122" },
  { index: 5, path: "projects-5.webp", link: "", width: "96" },

  { index: 3, path: "projects-3.webp", link: "", width: "99" },
  { index: 8, path: "projects-8.webp", link: "", width: "86" },
  { index: 4, path: "projects-4.webp", link: "", width: "77" },
  { index: 6, path: "projects-6.webp", link: "", width: "69" },

  { index: 7, path: "projects-7.webp", link: "", width: "86" },
  { index: 9, path: "projects-9.webp", link: "", width: "68" },
  { index: 10, path: "projects-10.webp", link: "", width: "40" },
  { index: 12, path: "projects-12.webp", link: "", width: "65" },
  { index: 11, path: "projects-11.webp", link: "", width: "68" },

  { index: 14, path: "projects-14.webp", link: "", width: "62" },
  { index: 13, path: "projects-13.webp", link: "", width: "90" },
  { index: 15, path: "projects-15.webp", link: "", width: "83" },
  { index: 16, path: "projects-16.webp", link: "", width: "96" },

  { index: 18, path: "projects-18.webp", link: "", width: "71" },
  { index: 19, path: "projects-19.webp", link: "", width: "104" },
  { index: 20, path: "projects-20.webp", link: "", width: "74" },
  { index: 22, path: "projects-22.webp", link: "", width: "38" },
  { index: 17, path: "projects-17.webp", link: "", width: "40" },

  { index: 23, path: "projects-23.webp", link: "", width: "71" },
  { index: 24, path: "projects-24.webp", link: "", width: "103" },
  { index: 26, path: "projects-26.webp", link: "", width: "81" },
  { index: 27, path: "projects-27.webp", link: "", width: "76" },

  { index: 25, path: "projects-25.webp", link: "", width: "119" },
  { index: 21, path: "projects-21.webp", link: "", width: "59" },
  { index: 28, path: "projects-28.webp", link: "", width: "63" },
  { index: 29, path: "projects-29.webp", link: "", width: "90" },
];
</script>

<style scoped>
.page17{
  width: 100%;
}
.page17-title {
  width: 100%;
  white-space: break-space;
}
.thanks-list {
  display: flex;
  justify-content: center;
}
.list-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  flex: 1;
}
.list-item {
  height: 36px;
}
</style>
