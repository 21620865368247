<template>
  <div class="page12">
    <Card
      :title="$t('page12_title')"
      :sub-title="$t('page12_subtitle')"
    ></Card>
    <Tabs
      content-class="tab-content-wrapper"
      class="page12-tabs-wrapper"
      stepIcon
      :data="list"
      @change="changeHandler"
    >
      <SwiperSlide> <move_card_1></move_card_1></SwiperSlide>
      <SwiperSlide> <move_card_2></move_card_2></SwiperSlide>
      <SwiperSlide> <move_card_3></move_card_3></SwiperSlide>
      <SwiperSlide> <move_card_4></move_card_4></SwiperSlide>
    </Tabs>
  </div>
</template>

<script setup>
import Card from "../common/Card.vue";
import Tabs from "../common/Tabs.vue";
import move_card_1 from "./move_card_1.vue";
import move_card_2 from "./move_card_2.vue";
import move_card_3 from "./move_card_3.vue";
import move_card_4 from "./move_card_4.vue";
import { SwiperSlide } from "swiper/vue";
import { useI18n } from "vue-i18n";
import { computed } from 'vue';
const { t } = useI18n();
const list = computed(() => [
  {
    name: t("movies_shows"),
    icon: "",
    key: "1",
  },
  {
    name: t("game"),
    icon: "",
    key: "2",
  },
  {
    name: t("music"),
    icon: "",
    key: "3",
  },
  {
    name: t("VR_streaming"),
    icon: "",
    key: "4",
  },
]);

const changeHandler = (index, data) => {
  console.log("changeHandler", index, data);
};
</script>

<style scoped>
.page12 {
  width: 100%;
  white-space: break-spaces;
}
.page12-tabs-wrapper {
  margin-top: 30PX;
}
</style>
<style>
.tab-content-wrapper {
  border-radius: 20PX;
  background: #000;
}
</style>
