<template>
  <div class="page17">
    <Card>
      <template #title>
        <div class="page17-title" v-html="$t('page17_title')"></div>
      </template>
    </Card>
    <div class="thanks-list">
      <a :href="item.link" v-for="(item, index) in list" :key="index" target="_blank">
        <img
          :style="{ width: item.width }"
          v-lazy="require(`@/assets/v2/${item.path}`)"
        />
      </a>
    </div>
  </div>
</template>

<script setup>
import Card from "../common/Card.vue";
import link from "@/common/link";

const list = [
  {
    path: "projects-1.webp",
    width: "233PX",
    link: link.kubesphere,
  },
  {
    path: "projects-2.webp",
    width: "236PX",
    link: link.kubernetes
  },
  {
    path: "projects-3.webp",
    width: "193PX",
    link: link.juicefs
  },
  {
    path: "projects-4.webp",
    width: "154PX",
    link: link.headscale
  },
  {
    path: "projects-5.webp",
    width: "216PX",
    link: link.tailscale
  },
  {
    path: "projects-6.webp",
    width: "148PX",
    link: link.dify
  },
  {
    path: "projects-7.webp",
    width: "164PX",
    link: link.seafile
  },
  {
    path: "projects-8.webp",
    width: "160PX",
    link: link.redis_operator
  },
  {
    path: "projects-9.webp",
    width: "134PX",
    link: link.jan,
  },
  {
    path: "projects-10.webp",
    width: "80PX",
    link: link.rss,
  },
  {
    path: "projects-11.webp",
    width: "125PX",
    link: link.nitro
  },
  {
    path: "projects-12.webp",
    width: "136PX",
    link: link.predixy
  },
  {
    path: "projects-13.webp",
    width: "196PX",
    link: link.mongodb
  },
  {
    path: "projects-14.webp",
    width: "145PX",
    link:link.nvshare
  },
  {
    path: "projects-15.webp",
    width: "174PX",
    link: link.infisical
  },
  {
    path: "projects-16.webp",
    width: "206PX",
    link: link.langchain
  },
  {
    path: "projects-17.webp",
    width: "80PX",
    link: link.quasar
  },
  {
    path: "projects-18.webp",
    width: "146PX",
    link: link.padloc
  },
  {
    path: "projects-19.webp",
    width: "200PX",
    link: link.trustwallet
  }, 
  {
    path: "projects-20.webp",
    width: "140PX",
    link: link.envoyproxy
  },
  {
    path: "projects-21.webp",
    width: "118PX",
    link: link.restic
  },
  {
    path: "projects-22.webp",
    width: "76PX",
    link: link.zincsearch
  },
  {
    path: "projects-23.webp",
    width: "142PX",
    link: link.k3s
  },
  {
    path: "projects-24.webp",
    width: "206PX",
    link: link.authelia
  },
  {
    path: "projects-25.webp",
    width: "224PX",
    link: link.filebrowser
  },
  {
    path: "projects-26.webp",
    width: "142PX",
    link: link.lego
  },
  {
    path: "projects-27.webp",
    width: "140PX",
    link: link.velero
  },
  {
    path: "projects-28.webp",
    width: "126PX",
    link:link.s3rver
  },
  {
    path: "projects-29.webp",
    width: "180PX",
    link: link.citusdata
  },
];


</script>

<style scoped>
.page17 {
  width: 100%;
}
.page17-img {
  margin-top: 60PX;
}
.page17-title {
  width: 940PX;
  white-space: break-space;
}
.thanks-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20PX;
  align-items: center;
  margin-top: 60PX;
  position: relative;
  height: 381PX;
}
.thanks-list img {
  height: 80PX;
}
</style>
