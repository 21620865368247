import { useI18n } from "vue-i18n";
import { computed } from "vue";

export const pageShow = () => {
  const { locale } = useI18n();
  const status = computed(() => locale.value === "zh");
  return status;
};

export const initLang = () => {
  const { locale } = useI18n();
  const lang = localStorage.getItem("lang");
  if (lang) {
    locale.value = lang;
  } else {
    locale.value = navigator.language.includes("zh") ? "zh" : "en";
  }
};
