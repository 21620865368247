<template>
  <div class="desktop-test">
    <apps_card
      :background-img="bg"
      :icon="terminusos2Icon"
      :title="$t('wise_title')"
      :sub-title="subTitle"
      :href="link.wise_learn_more"
    >
      <object class="desktop-img-2" type="image/svg+xml" :data="desktopBG11" width="100%" ></object>
    </apps_card>
  </div>
</template>

<script setup>
import desktopBG11 from "../../assets/new/desktop-bg-5.svg";
import apps_card from "./apps_card.vue";
import terminusos2Icon from "../../assets/terminusos-icon-5.png";
import bg from "../../assets/terminusos-bg-5.svg";
import link from "@/common/link";
import { useI18n } from "vue-i18n";
import { computed } from 'vue';

const { t } = useI18n();
const subTitle = computed(()=> [
  t("wise_subtitle_1"),
  t("wise_subtitle_2"),
  t("wise_subtitle_3"),
]);
</script>

<style scoped>
.desktop-img-2 {
  margin-right: -16PX;
  margin-top: -1PX;
  width: 838PX;
}
</style>
