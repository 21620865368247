<template>
  <div
    class="card-container"
    :style="{
      alignItems: align === 'left' ? 'flex-start' : 'center',
      textAlign: align,
    }"
  >
    <div class="title">{{ props.title }}<slot name="title"></slot>
    </div>
    <div
      class="sub-title"
      :class="{ margin_top: props.subTitle || $slots['sub-title'] }"
    >
      {{ props.subTitle }}
      <slot name="sub-title"></slot>
    </div>
    <div class="page-content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";

const props = defineProps({
  title: String,
  subTitle: String,
  align: String,
});

const align = ref(props.align || "center");
</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: column;
}
.title {
  color: #000;
  width: 100%;
  text-align: center;
  font-family: Inter;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 123.077% */
  white-space: break-spaces;
  text-align: center;
}
.sub-title {
  color: #706d79;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding: 0 29px;
  margin-bottom: 20px;
}
.margin_top {
  margin-top: 12px;
}
</style>
