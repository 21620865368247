<template>
  <div class="svg-container">
    <div class="svg-shape"></div>
    <div class="svg-path"></div>
    <div class="svg-text">{{ $t("get_started") }}</div>
  </div>
</template>

<script setup></script>

<style scoped>
.svg-container {
  position: relative;
  width: 102px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg-shape,
.svg-path {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 8PX;
}

.svg-shape {
  background: linear-gradient(90deg, #ff7b01 0%, #ff4e42 46.71%, #8dbbff 100%);
  z-index: 2;
}

.svg-path {
  background-color: #0d0d0d;
  z-index: 3;
  top: 1PX;
  left: 1PX;
  bottom: 1PX;
  right: 1PX;
}
.svg-text {
  position: relative;
  z-index: 4;

  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */

  background: linear-gradient(90deg, #ff7b01 0%, #ff4e42 46.71%, #8dbbff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
