<template>
  <div class="page1">
    <Star />
    <div class="page1_enter">
      <div class="header_ray" :style="{transform: `scale(${page_ratio})`, transformOrigin: 'top'}">
        <div class="ray2">
          <img src="../../assets/v2/grid-layer2.png" alt="ray2">
        </div>
        <div class="header_ray_bulr1"></div>
        <div class="header_ray_bulr2"></div>
        <div class="header_ray_bulr3"></div>
      </div>

      <img class="star" src="../../assets/v2/star.svg" alt="star" :style="{transform: `scale(${page_ratio})`, transformOrigin: 'left'}">
      <!-- <img class="popular1" src="../../assets/v2/popular1.svg" alt="popular1">
      <img class="popular2" src="../../assets/v2/popular2.svg" alt="popular2">
      <img class="popular3" src="../../assets/v2/popular1.svg" alt="popular3">
      <img class="popular4" src="../../assets/v2/popular2.svg" alt="popular4"> -->
      <div class="content_container" :style="{transform: `scale(${page_ratio})`}">
        <div class="content_ray" >
          <div class="content_1 content">{{ $t('terminus_dsc_1') }}</div>

          <div class="content_box" ref="contentBoxRef">
            <div class="content_2">
              <div class="content">{{ $t('terminus_dsc_1_0') }}</div>
            </div>
            <div class="content_2">
              <div class="content">{{ $t('terminus_dsc_1_1') }}</div>
            </div>

            <div class="content_2">
              <div class="content">{{ $t('terminus_dsc_2_1') }}</div>
            </div>

            <div class="content_2">
              <div class="content">{{ $t('terminus_dsc_3_1') }}</div>
            </div>

            <div class="content_2">
              <div class="content">{{ $t('terminus_dsc_4_1') }}</div>
            </div>
            <div class="content_2">
              <div class="content">{{ $t('terminus_dsc_5_1') }}</div>
            </div>
          </div>
        </div>
      </div>


      <div class="footer_ray" :style="{transform: `scale(${page_ratio})`, transformOrigin: 'bottom'}">
        <div class="ray4">
            <img  src="../../assets/v2/grid-layer2.png" alt="ray4">
        </div>
        <div class="footer_ray_bulr1"></div>
        <div class="footer_ray_bulr2"></div>
        <div class="footer_ray_bulr3"></div>
      </div>
      <div :style="{transform: `scale(${page_ratio})`, transformOrigin: 'bottom'}" class="bottom-wrapper">
        <div class="stared" @click="openLink">
          <div class="stared_inter">
            <Link-Target :src="link.get_started">{{ $t('get_started') }}</Link-Target>
            <div class="enter-img-wrapper">

            <img class="enter" src="../../assets/webp/enter.webp" alt="enter">
            </div>

          </div>
        </div>
        <div class="spot_wrap">
          <div class="spot">
            <span></span>
          </div>
        </div>
    </div>

    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue';
import { page_ratio } from "@/stores/platform";

import Star from '../common/Star';
import link from '../../common/link'

const contentBoxRef = ref();

onMounted(() => {
  const contentBox = contentBoxRef.value.children;

  for (let i = 0; i < contentBox.length; i++) {
    const element = contentBox[i];
    element.style['animation-duration'] = `${contentBox.length * 2.3}s`;
    element.style['animation-delay'] = `${ 0.2 + i * 2.3}s`;
  }

  const style = ref(null);
  style.value = document.createElement('style');
  document.head.appendChild(style.value);

  nextTick(() => {
    style.value.innerHTML = `@keyframes flipAni {
      0% {top: 100%; opacity: 0}
        ${100/(contentBox.length * 2) * 1}% {top: 0; opacity: 1}
        ${100/(contentBox.length * 2) * 2}% {top: 0; opacity: 1}
        ${100/(contentBox.length * 2) * 2.3}% {top: -30; opacity: 0}
        ${100/(contentBox.length * 2) * 3}% {top: -100%; opacity: 0}
        31% {top: 100%; opacity: 0}
        95% {top: 100%; opacity: 1}
        100% {top: 100%; opacity: 1}
    }`;
  })
})

const openLink = () => {
  window.open(link.get_started)
}

</script>

<style scoped src="../../style/raw.animation.css" />
<style scoped src="../../style/content.animation.css" />
<style scoped src="../../style/star.animation.css" />
<style scoped>
.page1 {
  width: 100%;
  background-color: #09090B;
  background-position: bottom;
  background-size: cover;
  height: calc(100vh - 72px);
  margin-top: 72PX;
  min-height: 744PX;
}

.page1_enter {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/webp/ray-left.webp'), url('../../assets/v2/ray-center.png'), url('../../assets/webp/ray-right.webp');
  background-position: left center, center 43%, right center;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: auto 50%, auto 40%, auto 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40PX 0 80PX;
}

.stared {
  padding-left: 20PX;
  height: 56PX;
  background: url('../../assets/v2/btn-border.png') no-repeat;
  background-position: center;
  background-size: contain;
  animation: staredAni 1s ease-in-out 0.5s forwards;
  opacity: 0;
  cursor: pointer;
}

@keyframes staredAni {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.stared_inter {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: 56PX;
  color: #FFF;
  font-size: 20PX;
  font-weight: 500;
  letter-spacing: -0.4PX;
  position: relative;
  display: flex;
  justify-content: center;
  gap: 12PX;
}

.enter-img-wrapper {
  width: 36PX;
}
.stared_inter .enter {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20PX;
  margin: auto;
  animation: enterdAni 2s linear infinite;
}

@keyframes enterdAni {
  0% {
    right: 20PX;
  }
  50% {
    right: 10PX;
  }
  100% {
    right: 20PX;
  }
}

.spot_wrap {
  width: 26PX;
  height: 37PX;
  box-sizing: border-box;
  border: 2PX solid #fff;
  border-radius: 13PX;
  animation: staredAni 1s ease-in-out 0.5s forwards;
  opacity: 0;
}

.spot {
  width: 100%;
  height: 100%;
  position: relative;
}

.spot span {
  display: inline-block;
  width: 0;
  height: 8PX;
  border: 1PX solid #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 2PX;
  bottom: 0;
  margin: auto;
  animation: spotAni 2s linear infinite;
}

@keyframes spotAni {
  0% {
    top: -14PX;
  }
  50% {
    top: 12PX;
  }
  100% {
    top: -14PX;
  }
}
.bottom-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 52PX;
  align-items: center;
  bottom: 8PX;
  left: 0;
  right: 0;
  margin: auto;
}



</style>
