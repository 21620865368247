<template>
  <div class="page4">
    <Card
      :title="$t('page4_title')"
      :sub-title="$t('page4_subtitle')"
    >
    </Card>
    <Tabs
      content-class="page4-tab-content-wrapper"
      class="page4-tabs"
      position="center"
      :data="list"
      @change="changeHandler"
    >
      <SwiperSlide><desktop_card_1></desktop_card_1></SwiperSlide>
      <SwiperSlide><desktop_card_2></desktop_card_2></SwiperSlide>
      <SwiperSlide><desktop_card_3></desktop_card_3></SwiperSlide>
      <SwiperSlide><desktop_card_4></desktop_card_4></SwiperSlide>
      <SwiperSlide><desktop_card_5></desktop_card_5></SwiperSlide>
      <SwiperSlide><desktop_card_6></desktop_card_6></SwiperSlide>
      <SwiperSlide><desktop_card_7></desktop_card_7></SwiperSlide>
    </Tabs>
  </div>
</template>

<script setup>
import Card from "../common/Card.vue";
import Tabs from "../common/Tabs.vue";
import desktopApp from "../../assets/v2/desktop-app.svg";
import filesApp from "../../assets/v2/files-app.svg";
import vaultApp from "../../assets/v2/vault-app.svg";
import marketApp from "../../assets/v2/market-app.svg";
import wiseApp from "../../assets/v2/wise-app.svg";
import manageApp from "../../assets/v2/manage-app.svg";
import developApp from "../../assets/v2/develop-app.svg";

import desktopAppActive from "../../assets/v2/desktop-app-active.svg";
import filesAppActive from "../../assets/v2/files-app-active.svg";
import vaultAppActive from "../../assets/v2/vault-app-active.svg";
import marketAppActive from "../../assets/v2/market-app-active.svg";
import wiseAppActive from "../../assets/v2/wise-app-active.svg";
import manageAppActive from "../../assets/v2/manage-app-active.svg";
import developAppActive from "../../assets/v2/develop-app-active.svg";
import desktop_card_1 from './desktop_card_1.vue'
import desktop_card_2 from './desktop_card_2.vue'
import desktop_card_3 from './desktop_card_3.vue'
import desktop_card_4 from './desktop_card_4.vue'
import desktop_card_5 from './desktop_card_5.vue'
import desktop_card_6 from './desktop_card_6.vue'
import desktop_card_7 from './desktop_card_7.vue'
import { SwiperSlide } from "swiper/vue";

import { useI18n } from "vue-i18n";
import { computed } from 'vue';
const {t}  = useI18n();

const list = computed(() => [
  {
    name: t("desktop"),
    icon: desktopApp,
    iconActive: desktopAppActive,
    key: "1",
  },
  {
    name: t("files"),
    icon: filesApp,
    iconActive: filesAppActive,
    key: "2",
  },
  {
    name: t("vault"),
    icon: vaultApp,
    iconActive: vaultAppActive,
    key: "3",
  },
  {
    name: t("market"),
    icon: marketApp,
    iconActive: marketAppActive,
    key: "4",
  },
  {
    name: t("wise"),
    icon: wiseApp,
    iconActive: wiseAppActive,
    key: "5",
  },
  {
    name: t("manage"),
    icon: manageApp,
    iconActive: manageAppActive,
    key: "6",
  },
  {
    name: t("develop"),
    icon: developApp,
    iconActive: developAppActive,
    key: "7",
  },
]);
const changeHandler = (index, data) => {
  console.log("changeHandler", index, data);
};
</script>

<style scoped>
.page4 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.page4-tabs {
  margin-top: 24PX;
}

</style>
<style>
.page4-tab-content-wrapper {
  /* overflow: inherit; */
  border-radius: 20PX;
  width: calc(100% + 30PX);
}
.page4-bolder {
  color: #6D6D6D;
  font-weight: 600;
}
</style>