<template>
  <div class="pc-home-container">
    <Page1 />
    <PageCard1 name="4" lazy>
      <Page4 />
    </PageCard1>
    <PageCard1 name="5" showBg lazy>
      <Page5 />
    </PageCard1>
    <PageCard1 name="6" lazy>
      <Page6 />
    </PageCard1>
    <PageCard1 name="7" showBg lazy>
      <Page7 />
    </PageCard1>
    <PageCard1 name="8" lazy>
      <Page8 />
    </PageCard1>
    <PageCard1 name="9" lazy>
      <Page9 />
    </PageCard1>
    <PageCard1 name="10" showBg lazy>
      <Page10 />
    </PageCard1>
    <PageCard1 name="11" lazy>
      <Page11 />
    </PageCard1>
    <PageCard1 name="12" lazy>
      <Page12 />
    </PageCard1>
    <PageCard1 name="13" showBg lazy>
      <Page13 />
    </PageCard1>
    <PageCard1 name="14" lazy v-if="!lang_zh">
      <Page14 />
    </PageCard1>
    <PageCard1 name="17" lazy>
      <Page17 />
    </PageCard1>
    <div class="page18-home-container">
      <PageCard1 name="18" style="background-color:#0d0d0d;" content-bg="#0d0d0d" lazy>
          <Page18 class="page18-home-wrapper" />
      </PageCard1>
      <div v-if="lang_zh" class="icp-container" :style="{transform: `scale(${page_ratio})`}">
        <a class="icp-text" href="https://beian.miit.gov.cn/" target="_blank">京ICP备2024083577号</a>
      </div>
    </div>
  </div>

</template>

<script setup>
import PageCard1 from "../common/PageCard1.vue";
import Page1 from "./page1.vue";
import Page4 from "./page4.vue";
import Page5 from "./page5.vue";
import Page6 from "./page6.vue";
import Page7 from "./page7.vue";
import Page8 from "./page8.vue";
import Page9 from "./page9.vue";
import Page10 from "./page10.vue";
import Page11 from "./page11.vue";
import Page12 from "./page12.vue";
import Page13 from "./page13.vue";
import Page14 from './page14.vue';
import Page17 from "./page17.vue";
import Page18 from './page18.vue';

import { onMounted } from 'vue';
import { WOW } from "wowjs";
import { page_ratio } from "@/stores/platform";
import { lang_zh } from "@/utils/lang";

onMounted(() => {
  const wow = new WOW({
    boxClass: "wow",
    animateClass: "animated",
    offset: 10,
    mobile: true,
    live: true,
    scrollContainer: null,
  });
  wow.init();
});
</script>

<style src="wowjs/css/libs/animate.css"></style>
<style src="../../style/pc.css"></style>
<style scoped>
.pc-home-container {
  display: flex;
  flex-direction: column;
  gap: 56PX;
  width: 100vw;
  overflow-x: hidden;
  max-width: 100%;
}
</style>
<style>
.pc-home-container .page1-title-highlight {
  background: linear-gradient(90deg, #fb7d47 71.92%, #ff6e6e 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
  font-size: 52PX;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1.04PX;
}
.pc-home-container .page1-dsc-item {
  color: #000;
  font-family: Inter;
  font-size: 20PX;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}
</style>
<style>

.link-flash {
  --ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
  position: relative;
  color: #77ADFF;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.4PX;
  cursor: pointer;
  font-size: 18PX;
  display: inline-block;
}
.link-flash::before, .link-flash::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0rem;
  display: block;
  width: 100%;
  height: 1PX;
  background: #77ADFF;
  transition: 1.1s var(--ease-out-expo);
}
.link-flash::before {
  transform: scaleX(0);
  transform-origin: left;
}
.link-flash::after {
  transform-origin: right;
  transition-delay: 0.25s;
}
.link-flash:hover::before {
  transform: scaleX(1);
  transition-delay: 0.25s;
}
.link-flash:hover::after {
  transform: scaleX(0);
  transition-delay: 0s;
}

a {
  color: white;
  text-decoration: none;
}

.link-flash2:hover {
  color:#FFF;
  font-weight: 500;
  opacity: 1;
  text-decoration-line: underline;
}
.link-flash-wrapper {
  font-size: 13PX;
}
.page18-home-container {
  position: relative;
}
.page18-home-wrapper {
  padding-bottom: 94PX;
}
.icp-container {
  color: #fff;
  text-align: center;
  font-size: 12PX;
  font-style: normal;
  font-weight: 400;
  line-height: 18PX;
  letter-spacing: -0.3PX;
  opacity: 0.5;
  position: absolute;
  bottom: 32PX;
  left: 0;
  right: 0;
}
.icp-text {
  color: #fff;
}
</style>
