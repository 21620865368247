<template>
  <div class="desktop-card-6">
    <apps_card_mini
      :background-img="item.bg"
      :icon="item.icon"
      :title="item.title"
      :sub-title="item.subTitle"
      v-for="(item, index) in data"
      :key="index"
      :href="item.link"
      class="apps-mini-wrapper"
    >
      <object type="image/svg+xml" :data="item.img" :class="item.classname" ></object>
    </apps_card_mini>
  </div>
</template>

<script setup>
import desktopBG1 from "../../assets/new/desktop-bg-6_1.svg";
import desktopBG2 from "../../assets/new/desktop-bg-6_2.svg";
import desktopBG3 from "../../assets/new/desktop-bg-6_3.svg";
import apps_card_mini from "./apps_card_mini.vue";
import icon1 from "../../assets/terminusos-icon-6_1.png";
import bg1 from "../../assets/terminusos-bg-6_1.svg";
import icon2 from "../../assets/terminusos-icon-6_2.png";
import bg2 from "../../assets/terminusos-bg-6_2.svg";
import icon3 from "../../assets/terminusos-icon-6_3.png";
import bg3 from "../../assets/terminusos-bg-6_3.svg";
import link from "@/common/link";
import { useI18n } from "vue-i18n";
import { computed } from 'vue';

const { t } = useI18n();
const data = computed(()=> [
  {
    title: t("settings"),
    subTitle: t("settings_subtitle"),
    icon: icon1,
    bg: bg1,
    img: desktopBG1,
    classname: "img-1",
  },
  {
    title: t("dashboard"),
    subTitle: t("dashboard_title"),
    icon: icon2,
    link: link.dashboard_learn_more,
    bg: bg2,
    img: desktopBG2,
    classname: "img-2",
  },
  {
    title: t("profile"),
    subTitle: t("profile_subtitle"),
    icon: icon3,
    link: link.profile_learn_more,
    bg: bg3,
    img: desktopBG3,
    classname: "img-3",
  },
]);
</script>

<style scoped>
.desktop-card-6 {
  display: flex;
  gap: 26PX;
  width: 200PX;
  width: 100%;
  padding-right: 30PX;
}
.apps-mini-wrapper {
  flex: 1;
  height: 500PX;
}
.img-1 {
  height: 100%;
  margin-left: -30PX;
}
.img-2 {
  height: 100%;
}
.img-3 {
  height: 100%;
}
</style>
