<template>
  <div class="page11">
    <Card :title="$t('page11_title')">
      <template #sub-title>
        <span v-html="$t('page11_subtitle')"></span>&nbsp;
        <ReadMore :link="link.AI_assistant_learn_more"></ReadMore>
      </template>
    </Card>
    <div class="page12-content">
      <div
        v-for="(item, index) in list"
        :key="index"
        class="item-wrapper"
        activeClass="page11-expand-active-class"
      >
        <div class="item-title">{{ item.title }}</div>
        <img v-if="index === 0" class="page11-img" v-lazy="item.img" alt="" />
        <object v-else class="page11-img" type="image/svg+xml" :data="item.img" width="100%" ></object>
        <div class="item-subtitle">{{ item.subTitle }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Card from "../common/CardMobile.vue";
import { useI18n } from "vue-i18n";
import img1 from "../../assets/v2/assistant-1.webp";
import img2 from "../../assets/v2/assistant-2.svg";
import img3 from "../../assets/v2/assistant-3.svg";
import img4 from "../../assets/v2/assistant-4.svg";
import img2_zh from "../../assets/zh/assistant-2.svg";
import img3_zh from "../../assets/zh/assistant-3.svg";
import img4_zh from "../../assets/zh/assistant-4.svg";
import ReadMore from "./ReadMore.vue";
import link from "@/common/link";
import { computed } from 'vue';

const { t,locale } = useI18n();
const list = computed(()=> [
  {
    title: t("build_agent"),
    subTitle: t("build_agent_dsc"),
    img: img1,
    lazy: true
  },
  {
    title: t("seamless_integration_knowledge"),
    subTitle: t("seamless_integration_knowledge_dsc"),
    img: locale.value === 'en' ? img2 : img2_zh,
  },
  {
    title: t("complete_jobs"),
    subTitle: t("complete_jobs_dsc"),
    img: locale.value === 'en' ? img3 : img3_zh,
  },
  {
    title: t("agent_service"),
    subTitle: t("agent_service_dsc"),
    img: locale.value === 'en' ? img4 : img4_zh,
  },
]);
</script>

<style scoped>
.page11 {
  width: 100%;
}
.page12-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.page11-read-more {
  color: #77adff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.4px;
  text-decoration-line: underline;
}
.page11-img {
  width: 100%;
  margin: 24px 0;
}
.item-wrapper {
  padding: 32px;
  border-radius: 20px;
  border: 1px solid #ddd;
  background: var(--horizon-ui-com-nero, #fff);
  box-shadow: 9.604px 11.662px 27.44px 2.744px rgba(112, 144, 176, 0.08);
}
.item-title {
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  text-transform: capitalize;
}
.item-subtitle {
  color: #706D79;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
</style>
