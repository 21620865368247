<template>
  <div class="move-1-container">
    <img class="move-bg" v-lazy="move1" alt="" />
    <div class="title2 page12-title">{{ $t("movies_shows_title") }}</div>
    <div class="sub-title2 page12-sub-title">
      {{ $t("movies_shows_subtitle") }}
    </div>
    <div class="button-container">
      <a class="button-text" :href="link.movies_learn_more" target="_blank">{{ $t("learn_more") }}</a>
    </div>
    <div class="move-list">
      <div class="move-item" v-for="item in list" :key="item">
        <img v-lazy="require(`@/assets/v2/${item}.png`)" alt="" />
      </div>
    </div>
  </div>
  <img src="" alt="" />
</template>

<script setup>
import move1 from "../../assets/webp/move-1.webp";
import link from "@/common/link";
const list = [
  "Rectangle-1",
  "Rectangle-2",
  "Rectangle-3",
  "Rectangle-4",
  "Rectangle-5",
  "Rectangle-6",
  "Rectangle-7",
  "Rectangle-8",
];
</script>

<style scoped>
.move-1-container {
  padding: 50PX;
  width: 100%;
  height: 486PX;
  position: relative;
}
.move-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.page12-title {
  color: #fff;
  font-family: Inter;
  font-size: 32PX;
  font-style: normal;
  font-weight: 700;
  line-height: 35PX; /* 109.375% */
}

.page12-sub-title {
  width: 748PX;
  color: #fff;
  font-family: Inter;
  font-size: 16PX;
  font-style: normal;
  font-weight: 500;
  line-height: 24PX; /* 150% */
  margin: 20PX 0;
}

.button-container {
  display: inline-flex;
  padding: 12PX 20PX;
  justify-content: center;
  align-items: center;
  border-radius: 12PX;
  background: #fff;
}
.button-text {
  color: #000;
  font-family: Inter;
  font-size: 16PX;
  font-style: normal;
  font-weight: 500;
  line-height: 24PX; /* 150% */
  letter-spacing: -0.4PX;
}
.move-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40PX;
}
.move-item {
  width: 124PX;
  height: 186PX;
  flex-shrink: 0;
  border-radius: 8PX;
  border: 1PX solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
}
.move-item img {
  width: 100%;
  height: 100%;
}
</style>
