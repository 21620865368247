const en = {
    page1_title_top: 'Olares One',
    page1_title :'为创意而生的 AI 个人云',
    page1_title_2: '搭载开源操作系统 Olares<span class="zero-separator"></span>本地 AI<span class="zero-separator"></span>设计师超级工作站',
    page1_title_2_m: '搭载开源操作系统 Olares本地 AI<span class="zero-separator"></span>设计师超级工作站',
    page1_btn_text: '立即订购',
    page1_btn_text2: '了解更多',
    page2_title: '六大心动理由',
    page2_card1_title: '开源操作系统 Olares',
    page2_card1_subtitle1: '利用私有数据搭建本地 AI',
    page2_card1_subtitle2: '安全开放的应用生态',
    page2_card1_subtitle3: '专属域名，随时随地访问',
    page2_card1_subtitle4: '个人信息中枢，无缝同步互联网散落数据',
    page2_card2_title: 'GeForce RTX 4060 Ti 独立显卡',
    page2_card2_subtitle1: '16GB 超高速 GDDR6 显存',
    page2_card2_subtitle2: '353 AI TOPS，轻松驾驭 AI 算力需求',
    page2_card3_title: 'ITX 机箱 小空间大作为',
    page2_card3_subtitle1: '双曲面玻璃，视野更通透',
    page2_card3_subtitle2: '高效散热设计',
    page2_card4_title: '48GB + 5TB   创意工作双引擎',
    page2_card4_subtitle1: '双通道 DDR5 内存',
    page2_card4_subtitle2: 'PCIe 4.0 高速 SSD',
    page2_card4_subtitle3: '5400 RPM HDD',
    page2_card5_title: 'AIGC 应用一触即达',
    page2_card5_subtitle1: '预装 Stable Diffusion、Comfy UI',
    page2_card5_subtitle2: '新手到大师，适合任何经验级别',
    page2_card6_title: '多用户支持',
    page2_card6_subtitle1: '多人一起用，创意流转更给力',
    page3_btn_tecxt: '利其器，善其事',
    page3_title: '设计师的下一台电脑，何必是电脑?',
    page3_title_mobile: '设计师的下一台电脑 何必是电脑?',
    page4_title: '一台 Olares One 助力设计创作',
    page4_title2: '摆脱信息差，开启设计潜能新维度',
    page5_title:'Stable Diffusion + ComfyUI  开箱即用，灵感不用等',
    page5_card_title_1: '免安装，0 踩坑',
    page5_card_subtitle_1: '1.2s 极速出图*',
    page5_card_title_2: '内置 1.2TB 模型和插件',
    page5_card_subtitle_2: '多样风格快速拿捏',
    page5_card_title_3: '16GB 显存加持',
    page5_card_subtitle_3: '无压运行 SDXL、SD3、Flux.1 模型',
    page5_footer_dsc:'* 生成图像的速度可能因系统负载、模型复杂性、图像大小、迭代步数等因素而异。',
    page6_title: '连接创意与效率，随时随地',
    page6_card_title_1: '强劲助力',
    page6_card_subtitle_1: 'AI + Photoshop，工作即刻开外挂*',
    page6_card_title_2: '随时开始',
    page6_card_subtitle_2: '在家中也能轻松连接，创意不间断，效率不打折',
    page6_card_title_3: '多人协同',
    page6_card_subtitle_3: '一台设备，多人同享，协作更高效',
    page6_footer_dsc: '*通过特定配置，可以在 PC 端 Photoshop、Krita 等设计软件中接入并使用 Olares One 的功能。',
    page7_title: '多款主流AI应用',
    page7_subtitle: '赋能创意工作流',
    page7_card1_title_1: 'Stable Diffusion',
    page7_card1_subtitle_1: '知名开源深度学习文生图模型',
    page7_card1_title_2: 'Flux.1',
    page7_card1_subtitle_2: '120亿参数，文生图模型新高度',
    page7_card2_title_1: 'ComfyUI',
    page7_card2_subtitle_1: '基于节点灵活定制图像生成流程',
    page7_card3_title_1: 'Open WebUI',
    page7_card3_subtitle_1: '在本地轻松运行多种LLM',
    page7_card3_title_2: 'Dify',
    page7_card3_subtitle_2: '生成式AI应用创新引擎',
    page8_title: '释放本地 AI 超能力',
    page8_subtitle: 'GeForce RTX 4060 Ti 16GB 独显',
    page8_card1_subtitle: 'GDDR6 显存',
    page8_card2_subtitle: 'CUDA 核心',
    page8_card3_subtitle: 'Time Spy 图形分',
    page8_card4_title_1: '更快',
    page8_card4_title_2: '更多',
    page8_card4_title_3: '更强',
    page8_card4_title_4: '更高',
    page8_card4_subtitle_1: '秒级高清图像生成',
    page8_card4_subtitle_2: '海量 AIGC 模型选择',
    page8_card4_subtitle_3: '深度学习超能力',
    page8_card4_subtitle_4: '顶级 3D 图形处理',
    page9_title: '应用丝滑不卡顿',
    page9_subtitle: 'Intel® 酷睿™ i5 12600KF 处理器',
    page9_card1_title_1: '10 核',
    page9_card1_subtitle_1: '核心数',
    page9_card1_title_2: '16 线程',
    page9_card1_subtitle_2: '多线程',
    page9_card1_title_3: '4.90 GHz',
    page9_card1_subtitle_3: '最大睿频频率',
    page9_card1_title_4: '20 MB',
    page9_card1_subtitle_4: 'Intel® Smart Cache',
    page9_card1_title_5: 'Intel® 7',
    page9_card1_subtitle_5: '高性能工艺',
    page9_card2_title_1: '极速与容量并存',
    page9_card2_subtitle_1: '轻松应对创作重头戏',
    page9_card2_title_2: '48GB DDR5',
    page9_card2_subtitle_2: '6800MHz 双通道内存',
    page9_card2_title_3: '1TB',
    page9_card2_title_4: '4TB',
    page9_card2_subtitle_3: ' PCIe 4.0 固态硬盘｜1GB 独立缓存',
    page9_card2_subtitle_4: '5400转 大容量机械硬盘',
    page10_title: '高负载操作 依然冷酷范',
    page10_card_title: '显卡风之力 <span class="page10-text">两风扇散热系统</span>',
    page10_card_subtitle: '低噪机箱风扇 安静工作不打扰',
    page11_title: '多样接口就位，扩展创意空间',
    page11_card1_title_1: '正面',
    page11_card1_subtitle_1: 'USB 3.0',
    page11_card1_subtitle_2: 'AUDIO接口',
    page11_card1_subtitle_3: 'USB 3.2 Type-C ',
    page11_card2_title_1: '背面',
    page11_card2_subtitle_1: 'USB 3.2 ',
    page11_card2_subtitle_2: 'DisplayPort接口',
    page11_card2_subtitle_3: 'HDMI接口',
    page11_card2_subtitle_4: 'USB 3.2 Type-C ',
    page11_card2_subtitle_5: 'USB 2.0',
    page11_card2_subtitle_6: '麦克风接口',
    page11_card2_subtitle_7: '线性输入输出接口',
    page11_card2_subtitle_8: 'Wi-Fi 6E 天线接口',
    page12_title: '颜值拉满 一眼心动',
    page12_subtitle: '小巧机身，大器性能。<span class="page12-title-wrapper"></span>黑胡桃木饰板与双曲面玻璃，美学与效能的完美结合。',
    page12_card1_title: '双R角一体折弯玻璃',
    page12_card1_subtitle: '270度曲面玻璃侧透，多角度硬件展示',
    page12_card2_title: '木饰板材，彰显精致',
    page12_card2_subtitle: '采用16mm厚度北美黑胡桃木，实木材质，真实纹理',
    page13_title: '开源操作系统<span class="zero_title_linear">Olares</span>',
    page13_subtitle: '无需任何配置，通过专属域名，随时随地畅享 Olares 应用和服务 <span class="page13-title-wrapper">简单直观的图形化交互界面</span>',
    page13_icons: {
        name_1:'AI 助手',
        name_2:'密码管理',
        name_3:'文件管理',
        name_4:'本地阅读器',
        name_5:'系统监控',
        name_6:'应用开发',
        name_7:'影音娱乐',
        name_8:'智能家居管理',
    },
    page14_title: '文件随心存取 协同创作无界限',
    page14_card: {
        card_1_title: '同步传',
        card_1_subtitle: '将散落在网络各处的宝贵文档、照片、视频和聊天记录自动同步至 Olares。',
        card_2_title: '随心看',
        card_2_subtitle: '使用内置或第三方应用查看、编辑、管理不同渠道的数据，并在 Olares 里沉淀为知识。',
        card_3_title: '直接问',
        card_3_subtitle: '通过智能搜索快速定位数据，并利用私有数据增强本地 AI Agent 的能力。',
        card_4_title: '放心存',
        card_4_subtitle: '在Olares 上安全高效地存储和备份您的数据。',
    },
    page15_title: '从办公到娱乐 应有尽有<div class="page15-title-container"><span class="zero_title_linear">80+</span><span class="page15-subtitle-wrapper">项应用 一键免费安装</span></div>',
    page15_icons: {
        name_1: 'AI设计',
        name_2: '办公协作',
        name_3: '效率提升',
        name_4: '影音娱乐',
        name_5: '远程下载',
        name_6: '智慧家居',
    },
    page16_title: '硬件参数',
    page_list: {
        title_1: '机箱',
        subtitle_1:'乔思博 TK-0',
        title_2: 'CPU',
        subtitle_2:'Intel i5 12600KF',
        title_3: '主板',
        subtitle_3:'微星 MPG B760I EDGE WIFI',
        title_4: '显卡',
        subtitle_4:'技嘉风魔 (GIGABYTE) GeForce RTX 4060 Ti WINDFORCE OC 16G',
        title_5: '内存',
        subtitle_5:'金百达 48G (24 * 2) DDR5  6800MHz',
        title_6: '存储',
        subtitle_6:'英睿达 T500 1TB pcie4.0 <div>希捷台式 5400转 3.5 SATA 4T HDD</div>',
        title_7: '散热',
        subtitle_7:'利民AXP90-X53 下压式全黑色无灯效',
        title_8: '风扇',
        subtitle_8:'全黑化低噪音扇叶 风扇 14cm *1+全黑化低噪音扇叶 风扇 9cm*2',
        title_9: '电源',
        subtitle_9:'航嘉MX650P 金牌全模组',
        title_10: '尺寸',
        subtitle_10:'235mm(W) * 250mm(D) * 280mm(H)',
        title_11: '重量',
        subtitle_11:'8.3 kg',
    },
    page17_title: 'Olares One',
    page17_subtitle: '为创意而生<span class="zero-separator"></span>AI 个人云<span class="zero-separator"></span>设计师超级工作站',
    page18_buy: '立即购买'

}

export default en;
