<template>
  <div class="move-1-container">
    <img class="move-bg" v-lazy="move1" alt="" />
    <div class="move-1-wrapper">
      <div class="title2 page12-title">{{ $t("movies_shows_title") }}</div>
      <div class="sub-title2 page12-sub-title">
        {{ $t("movies_shows_subtitle") }}
      </div>
      <div class="button-container">
        <a class="button-text" :href="link.movies_learn_more" target="_blank">{{
          $t("learn_more")
        }}</a>
      </div>
      <div class="move-list">
        <div class="move-item" v-for="item in list" :key="item">
          <img v-lazy="require(`@/assets/v2/${item}.png`)" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import move1 from "../../assets/mobile2/move-1.webp";
import link from "@/common/link";
const list = [
  "Rectangle-1",
  "Rectangle-2",
  "Rectangle-3",
  "Rectangle-4",
  "Rectangle-5",
  "Rectangle-6",
  "Rectangle-7",
  "Rectangle-8",
];
</script>

<style scoped>
.move-1-container {
  padding: 40px 24px;
  width: 100%;
  position: relative;
  background: #181818;
  border-radius: 24px;
  overflow: hidden;
}
.move-bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 100%;
  pointer-events: none;
}
.move-1-wrapper {
  position: relative;
}
.page12-title {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}

.page12-sub-title {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  margin: 20px 0;
  width: 238px;
}

.button-container {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.4px;
  padding: 4px 16px;
  border-radius: 8px;
  background: #fff;
  display: inline-block;
}
.button-text {
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.4px;
}
.move-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
  margin-top: 24px;
}
.move-item {
  width: 63px;
  height: 95px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
}
.move-item img {
  width: 100%;
  height: 100%;
}
</style>
