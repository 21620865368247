<template>
  <transition name="fade">
    <div class="w-message">
      <span class="s-message" :style="MESSAGE_STYLE[props.type]" v-if="isShow">
        <span class="text">{{ props.text }}</span>
      </span>
    </div>
  </transition>
</template>

<script>
import { ref, onMounted } from "vue";
import { MESSAGE_TIMEOUT, MESSAGE_STYLE } from "./../../common/constant";

export default {
  name: "Message",
  components: {},
  props: {
    text: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "warn"
    },
    timeout: {
      type: Number,
      default: MESSAGE_TIMEOUT
    }
  },

  setup(props) {
    const isShow = ref(false);

    onMounted(() => {
      isShow.value = true
      setTimeout(() => {
        isShow.value = false
      }, props.timeout)
    })

    return {
      isShow,
      props,
      MESSAGE_STYLE,
    };
  }
};

</script>

<style scoped>
.fade-enter-active {
  animation: fade .5s;
}

.fade-leave-active {
  animation: fade .5s reverse;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateY(-50PX);
  }

  100% {
    opacity: 1;
  }
}

.w-message {
  position: fixed;
  left: 0;
  right: 0;
  top: 25PX;
  margin: auto;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.s-message {
  padding: 8PX 20PX;
  border-radius: 4PX;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.text {
  vertical-align: middle;
}
</style>

